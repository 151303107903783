import React from "react";
import { BiCheck } from "react-icons/bi";
import { Link } from "react-router-dom";
import { GrClose } from "react-icons/gr";
import {
  EmailIcon,
  FacebookIcon,
  TelegramIcon,
  TwitterIcon,
  WhatsappIcon,
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  EmailShareButton,
  TelegramShareButton,
} from "react-share";
import { Button } from "react-bootstrap";
import "./ShareModal.css";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useTranslation } from "react-i18next";

const ShareModal = ({
  setOpenSharePopup,
  openSharePopup,
  shareCode,
  setShareCode,
  shareContent,
  setShareContent,
  onCopy,
  isCopied,
  setIsCopied,
  referral_code,
  refferelpage
}) => {
  const [t] = useTranslation("common");
  React.useEffect(() => {
    setTimeout(() => {
      // After 3 seconds set the isCopied value to false
      setIsCopied(false);
    }, 3000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChange = ({ target: { value } }) => {
    setShareCode(value);
    setShareContent(value);
    setIsCopied(true);
  };
  const ref = React.useRef(null);

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setOpenSharePopup(false);
    }
  };

  React.useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      {/* <div className={openSharePopup == true ?"show share-modal-backdrop": ""}> */}
      <div
        className={openSharePopup === true ? "sharePopup show" : "sharePopup"}
        // className={
        //   showBuyPointsPopup == true ? "  popupFadeIn" : "  popupFadeOut"
        // }
        ref={ref}
      >
        <div className="modalHeader">
          <p>{t("sharePopup.Share")} </p>

          <Button
            className={"popclose onClickBtn"}
            onClick={() => setOpenSharePopup(!openSharePopup)}
          >
            <GrClose />
          </Button>
        </div>
        <div className="content">
          <p>{t("sharePopup.Sharethislinkvia")}</p>
          <ul className="icons">
            <Link to="#">
              {/* <GrFacebookOption /> */}

              <FacebookShareButton
                url={
                  referral_code === shareCode
                    ? `https://wfrlee.com/sign-up?referral_code=${shareCode}`
                    : shareCode
                }
                // quote={}
               // quote={"SSSSS"}
               
             
                description={"aiueo"}
                className="Demo__some-network__share-button"
              >
                <FacebookIcon size={32} round />
              </FacebookShareButton>
            </Link>
            <Link to="#">
              {/* <GrTwitter /> */}
              <TwitterShareButton
                url={shareCode}
                className="Demo__some-network__share-button"
                title={refferelpage !== undefined ? `${t("sharePopup.ReferralMessage")} Here is my code (${referral_code}). https://wfrlee.com/sign-up?referral_code=${referral_code}`:""}
              >
                <TwitterIcon size={32} round />
              </TwitterShareButton>
            </Link>
            <Link to="#">
              <EmailShareButton
                url={shareCode}
                className="Demo__some-network__share-button"
                subject={refferelpage !== undefined ? `${t("sharePopup.ReferralMessage")} Here is my code (${referral_code}). https://wfrlee.com/sign-up?referral_code=${referral_code}`:""}
              >
                <EmailIcon size={32} round />
              </EmailShareButton>
            </Link>

            {/* <Link to="#">
            <GrInstagram />
            <InstapaperShareButton
              url={shareCode}
              className="Demo__some-network__share-button"
            >
              <InstapaperIcon size={32} round />
            </InstapaperShareButton>
          </Link> */}
            <Link to="#">
              {/* <BsWhatsapp /> */}
              <WhatsappShareButton
                url={refferelpage !== undefined ? `${t("sharePopup.ReferralMessage")} Here is my code (${referral_code}). https://wfrlee.com/sign-up?referral_code=${referral_code}`: ( (shareContent && shareContent !== undefined) ? shareContent : shareCode) }
                className="Demo__some-network__share-button"
               
              >
              {/* { console.log("shareContent-->>", shareContent) } */}
                <WhatsappIcon size={32} round />
              </WhatsappShareButton>
            </Link>
            <Link to="#">
              {/* <BsTelegram /> */}
              <TelegramShareButton
                url={shareCode}
                className="Demo__some-network__share-button"
                title={refferelpage !== undefined ?   `${t("sharePopup.ReferralMessage")} Here is my code (${referral_code}). https://wfrlee.com/sign-up?referral_code=${referral_code}`:""}
              >
                <TelegramIcon size={32} round />
              </TelegramShareButton>
            </Link>
            {/* <Link to="#">
               <BsTelegram /> 
              <FacebookMessengerShareButton
                appId="1680054789177285"
                redirectUri="https://wfrlee.com/"
                className="Demo__some-network__share-button"
               
              >
                <TelegramIcon size={32} round />
              </FacebookMessengerShareButton>
            </Link> 
        */}
          </ul>
          <p>{t("sharePopup.Orcopylink")}</p>
          <div className="field copyToClipboardArea">
            <input
              type="text"
              readOnly
              value={shareCode}
              onChange={onChange}
              // name="shareCode"
            />
            <CopyToClipboard text={shareCode} onCopy={() => onCopy(true)}>
              <Button className={"copyLinkBtn"}>{t("sharePopup.Copy")}</Button>
            </CopyToClipboard>
          </div>
        </div>
      </div>
      {isCopied ? (
        <div className="copiedToClipboard">
          <div className="container">
            {" "}
            <div className="d-flex justify-content-between">
              <p>
                <BiCheck />
                {t("sharePopup.CopiedtoClipboard")}
              </p>
              <p className="clipbd-popcloses" onClick={() => onCopy(false)}>
                <GrClose />
              </p>
            </div>
          </div>
        </div>
      ) : null}
      {/* </div> */}
    </>
  );
};

export default ShareModal;
