import OfferActionTypes from "./offer.types";

const INITIAL_STATE = {
  loading: false,
  error: null,
  couponListLoading: false,
  getCouponListFailedData: null,
  couponListData: null,
};

const offerReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    /**** COUPON LIST Reducer */
    case OfferActionTypes.GET_COUPON_LIST_REQUEST:
      return {
        ...state,
        couponListLoading: true,
      };
    case OfferActionTypes.GET_COUPON_LIST_SUCCESS:
      return {
        ...state,
        couponListData: action.payload,
        couponListLoading: false,
      };
    case OfferActionTypes.GET_COUPON_LIST_FAILED:
      return {
        ...state,
        error: action.payload,
        couponListLoading: false,
      };
    default:
      return state;
  }
};

export default offerReducer;
