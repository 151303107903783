import { takeLatest, put, all, call } from "redux-saga/effects";
import axios from "axios";
import OfferActionTypes from "./offer.types";
import { errorToast, successToast } from "../../utils/toastHelper";
import { getCouponListSuccess, getCouponListFailure } from "./offer.actions";
import store from "./../store";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
  var MySwal = withReactContent(Swal);

var backendUrlEndPoint = process.env.REACT_APP_BACKEND_API_ENDPOINT;

/************ Get Coupon List    *************** */
export function* getCouponListAsync({ payload: data }) {
  try {
    let response = yield axios.get(`${backendUrlEndPoint}/coupon/get-all?type=promo&is_active=active`, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        mobile: true,
        "Accept-Language": `${store.getState().language.language}`,
        Authorization:
          store.getState().user.currentUser != null
            ? "Bearer " + store.getState().user.currentUser.token + ""
            : "",
      },
      params: {
        skip: data.skip,
        limit: data.limit,
      },
    });
    if(data.scroll != undefined) {
    const offersdataOldList = store?.getState()?.offer?.couponListData != null ?  store?.getState()?.offer?.couponListData.data : "";  
    var a = offersdataOldList;
    var b = response.data.data;
    var dataListAll =  [...a,...b];    
  
  response.data.data = dataListAll;

  }
    if (response.data.success === true) {
      yield put(getCouponListSuccess(response.data));
      // successToast(response.data.message);
    } else {
      yield put(getCouponListFailure(response.data));
      MySwal.fire({
        confirmButtonText: 'OK',
        confirmButtonColor: '#296da9',
        text:response.data.message
      });  
     // errorToast(response.data.message);
    }
  } catch (error) {
    // console.log("Network Error", error);
  }
}

/************  Fetch Get Coupon List  *************** */
export function* getCouponListFetchStart() {
  yield takeLatest(
    OfferActionTypes.GET_COUPON_LIST_REQUEST,
    getCouponListAsync
  );
}

export function* offerSagas() {
  yield all([call(getCouponListFetchStart)]);
}
