import CurrencyActionTypes from "./currency.types";
const INITIAL_STATE = {
  currency: {
    current: "SAR",
    prev: "SAR",
    convertedRates: 1,
  },
};

const currencyReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CurrencyActionTypes.SELECT_DEFAULT_CURRENCY:
      return {
        ...state,
        currency: {
          current: action.payload.current,
          prev: "SAR",
          convertedRates:
            action.payload.current === "SAR"
              ? 1
              : action.payload.convertedRates,
        },
      };
    default:
      return state;
  }
};

export default currencyReducer;
