import React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { useBookingDetailsDialogStyles } from "./BookingDetailsDialogStyles";
import DialogTitle from "@mui/material/DialogTitle";
import { GrClose } from "react-icons/gr";
import Slider from "react-slick";
import moment from "moment";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { selectlanguageToShow } from "./../../redux/language/language.selectors";
import { selectcurrencyToShow } from "./../../redux/currency/currency.selectors";
import { useTranslation } from "react-i18next";

const BookingConfirmationDetailsModal = ({
  setShowDialog,
  isOpen,
  bookingData,
  cancelButtonLabel,
  cancelDialogHandler,
  currencyToShow,
  languageToShow,
}) => {
  const classes = useBookingDetailsDialogStyles();
  const [open, setOpen] = React.useState(isOpen);
  const [scroll, setScroll] = React.useState("paper");
  const [t] = useTranslation("common");

  React.useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  var collecSlider = {
    arrows: true,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const renderPriceBreakup = () => {
    return (
      <Box className={classes.hotelDetailsContents}>
        <Box className="WL_paymentwrp hotel-details-card p-2">
          <Box className="ps_top_header">
            <div>
              <p className="pb-0">
                {t("MyAccount.MyBookingsPage.bookingDetailsPopup.Invoice")}
              </p>
              {bookingData?.coupon_details != null
                ? bookingData?.coupon_details.length > 0
                  ? bookingData?.coupon_details.map((coupon, index) => (
                    <p key={index}>
                      {t(
                        "MyAccount.MyBookingsPage.bookingDetailsPopup.PromocouponcodeUsed"
                      )}
                      :{" "}
                      <span style={{ color: "green" }}>
                        {coupon.code}
                        {`(${coupon.discount_amount}${coupon.discount_type === "percentage" ? "%" : ""
                          })`}
                      </span>
                    </p>
                  ))
                  : ""
                : ""}
            </div>
            <p>{t("MyAccount.MyBookingsPage.bookingDetailsPopup.Price")}</p>
            <p>{t("MyAccount.MyBookingsPage.bookingDetailsPopup.Total")}</p>
          </Box>
          {bookingData?.price_breakup != null &&
            bookingData?.price_breakup
              .filter(
                (el) =>
                  el.fee_type !== "tax_amount" &&
                  el.fee_type !== "municipal_tax_amount" &&
                  el.fee_type !== "is_tax_percentage" &&
                  el.fee_type !== "is_municipal_tax_percentage" &&
                  el.fee_type !== "initial_hotel_amount" &&
                  el.fee_type !== "wfrlee_commission" &&
                  el.fee_type !== "amount"
              )
              .map((item, index) =>


                item.fee_type == "is_service_fee_percentage" ? (
                  ""
                ) : (
                  <Box

                    key={index}
                    className={
                      item.fee_type === "amount" ||
                        item.fee_type === "amount_to_be_paid_now" ||
                        item.fee_type === "wallet_balance_applied" ||
                        item.fee_type === "coupon_amount_applied"
                        ? "boldText WL_payment_inerwrp"
                        : "WL_payment_inerwrp"
                    }
                  >
                    <div className="WL_payment_iner_left">
                      {item.fee_type === "taxes"
                        ? t(
                          "MyAccount.MyBookingsPage.bookingDetailsPopup.Vat15per"
                        )
                        : item.fee_type === "municipal_taxes"
                          ? bookingData?.hotel_details?.star > 3
                            ? t(
                              "MyAccount.MyBookingsPage.bookingDetailsPopup.Municipality5per"
                            )
                            : t(
                              "MyAccount.MyBookingsPage.bookingDetailsPopup.Municipality2point5per"
                            )
                          : 


                          item.fee_type === "coupon_amount_applied" &&
                            languageToShow === "en"
                            ? "Promo/Coupon Code Used"
                            : item.fee_type === "coupon_amount_applied" &&
                              languageToShow === "ar"
                              ? "الرمز الترويجي / الكوبون مستخدم "
                              : item.fee_type === "wallet_balance_applied" &&
                                languageToShow === "en"
                                ? "Wfrlee Points"
                                : item.fee_type === "wallet_balance_applied" &&
                                  languageToShow === "ar"
                                  ? "نقاط وفرلي"
                                  : item.fee_type === "amount_to_be_paid_now" &&
                                    languageToShow == "en"
                                    ? "Total Amount"
                                    : item.fee_type === "amount_to_be_paid_now" &&
                                      languageToShow == "ar"
                                      ? " إجمالي المبلغ"
                                      : item.fee_type === "net_rate" && languageToShow == "en"
                                        ? "Net Rate"
                                        : item.fee_type === "net_rate" && languageToShow == "ar"
                                          ? "سعر بدون رسوم"
                                          : item.fee_type === "service_fee" &&
                                            languageToShow == "en"
                                            ? "Service Fee"
                                            : item.fee_type === "service_fee" &&
                                              languageToShow == "ar"
                                              ? "رسوم خدمة"
                                              : item.fee_name}
                      :
                    </div>
                    {item.fee_type === "net_rate" && (
                      <div className="WL_payment_iner_left">
                        {Number(
                          item.amount * currencyToShow.convertedRates
                        ).toFixed(2)}
                        {" " + currencyToShow?.current}
                      </div>
                    )}

                    <div className="WL_payment_iner_right">
                      {Number(
                        item.amount * currencyToShow.convertedRates
                      ).toFixed(2)}
                      {" " + currencyToShow?.current}

                    </div>
                  </Box>
                )
              )}
          {bookingData != null && bookingData.payment_type == "online" ? (
            <Box

              key={"16"}
              className={"boldText WL_payment_inerwrp"}
            >
              <div className="WL_payment_iner_left">
                {t("MyAccount.MyBookingsPage.bookingDetailsPopup.Amountpaid")}
              </div>

              <div className="WL_payment_iner_left"></div>

              <div className="WL_payment_iner_right">
                {bookingData != null
                  ? Number(
                    bookingData?.price_breakup.find(
                      (element) => element.fee_type == "amount_to_be_paid_now"
                    ).amount
                  ).toFixed(2)
                  : ""}{" "}
                {" " + bookingData != null ? bookingData?.currency : ""}
              </div>
            </Box>
          ) : (
            <>
              <Box

                key={"16"}
                className={"boldText WL_payment_inerwrp"}
              >
                <div className="WL_payment_iner_left">
                  {t("MyAccount.MyBookingsPage.bookingDetailsPopup.Amountpaid")}
                </div>

                <div className="WL_payment_iner_left"></div>

                <div className="WL_payment_iner_right">
                  {bookingData != null || bookingData != undefined
                    ? Number(
                      bookingData?.price_breakup.find(
                        (element) => element.fee_type == "service_fee"
                      ).amount
                    ).toFixed(2)
                    : ""}{" "}
                  {" " + bookingData != null ? bookingData?.currency : ""}
                </div>
              </Box>
            </>
          )}
          {bookingData != null && bookingData.payment_type != "online" ? (
            <Box

              key={"17"}
              className={"boldText WL_payment_inerwrp"}
            >
              <div className="WL_payment_iner_left">
                {t("MyAccount.MyBookingsPage.bookingDetailsPopup.Amounttopay")}
              </div>

              <div className="WL_payment_iner_left"></div>

              <div className="WL_payment_iner_right">
                {bookingData != null
                  ? (Number(
                    bookingData?.price_breakup.find(
                      (element) => element.fee_type == "amount_to_be_paid_now"
                    ).amount
                  ) -
                    Number(
                      bookingData.price_breakup.find(
                        (element) => element.fee_type == "service_fee"
                      ).amount
                    )).toFixed(2)
                  : ""}{" "}
                {" " + bookingData != null ? bookingData?.currency : ""}
              </div>
            </Box>
          ) : null}
        </Box>
      </Box>
    );
  };

  return (
    <React.Fragment>
      <Dialog
        fullScreen={fullScreen}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "900px", // Set your width here
            },
          },
        }}
        open={open}
        onClose={() => {
          setShowDialog(false);
          setOpen(false);
        }}
        aria-labelledby="responsive-dialog-title"
        className={classes.dialogPopup}
        scroll={scroll}
      >
        <DialogTitle>
          <Typography className={classes.heading}>
            {t("MyAccount.MyBookingsPage.bookingDetailsPopup.BookingDetails")}
          </Typography>
          <Box sx={{ position: "absolute", right: 0, left: "auto", top: 0 }}>
            <Button
              className={"popclose onClickBtn"}
              onClick={cancelDialogHandler}
            >
              <GrClose />
            </Button>
          </Box>
        </DialogTitle>
        <DialogContent className={classes.dialogContent} tabIndex={0}>
          <Box className="container">
            <div className="custom-paypop">
              <h2>
                {t("MyAccount.MyBookingsPage.bookingDetailsPopup.Thanks")}{" "}
                {bookingData?.booked_user?.first_name}!{" "}
                {t("MyAccount.MyBookingsPage.bookingDetailsPopup.YourRoomIn")}{" "}
                {bookingData?.hotel_details?.name} is{" "}

                {bookingData?.booking_status != null &&
                  bookingData?.booking_status == 1
                  ? t("MyAccount.MyBookingsPage.bookingDetailsPopup.Confirmed")
                  : bookingData?.booking_status_dec}
              </h2>
              <ul className="paylisting">
                <li>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-check-lg"
                    viewBox="0 0 16 16"
                  >
                    <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z" />
                  </svg>{" "}
                  {/* Rest Night Hotel Suites- - AL Nafal is expecting you on 2 May */}
                  {bookingData?.hotel_details?.name}{" "}
                  {t(
                    "MyAccount.MyBookingsPage.bookingDetailsPopup.isExpectingYouOn"
                  )}{" "}
                  {moment(new Date(`${bookingData?.checkin_date}`)).format(
                    "ddd, DD MMM, YYYY "
                  )}
                </li>
                <li>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-check-lg"
                    viewBox="0 0 16 16"
                  >
                    <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z" />
                  </svg>{" "}
                  {/* You can cancel for FREE until 30 April 2022 23:59 [+03]. */}
                  {t(
                    "MyAccount.MyBookingsPage.bookingDetailsPopup.YouCanCancelForFREEUntil"
                  )}{" "}
                  {moment(
                    new Date(`${bookingData?.cancellation_policy_details?.cancellation_until_date}`),
                    ["YYYY-MM-DD[T]HH:mm:ss.SSS[Z]"]
                  ).format("DD MMM, YYYY hh:mm A ")}
                  {/* 30 April 2022 23:59 [+03]. */}
                </li>
                <li>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-check-lg"
                    viewBox="0 0 16 16"
                  >
                    <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z" />
                  </svg>{" "}
                  {t(
                    "MyAccount.MyBookingsPage.bookingDetailsPopup.UseTheLinkBelowToEasilyModifyorCancelYourBooking"
                  )}
                </li>
              </ul>
              <div className="d-flex justify-content-between">
                <h3>
                  {bookingData?.hotel_details?.name}{" "}

                </h3>

              </div>
              <div className="d-flex justify-content-between align-items-center">
                <Box className="WL_coll_slider myFavColl_Slider hotel-content">
                  <Slider {...collecSlider}>
                    {bookingData?.hotel_details?.images.length > 0 ? (
                      bookingData?.hotel_details?.images.map((image, index) => (
                        <img
                          src={image}
                          alt=""
                          className="WL_coll_slider_img"
                          key={index}
                        />
                      ))
                    ) : (
                      <img
                        src="./img/collec_img1.png"
                        alt=""
                        className="WL_coll_slider_img"
                      />
                    )}
                  </Slider>
                </Box>
                <div className="locationarea">
                  <div className="locimg">
                    <img src="./img/img-11.jpg" alt="" />
                  </div>

                </div>
              </div>
              <div className="payinfo">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="22"
                  height="22"
                  fill="currentColor"
                  className="bi bi-info-circle"
                  viewBox="0 0 16 16"
                >
                  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                  <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                </svg>{" "}
                {bookingData?.payment_type == "online"
                  ? t(
                    "MyAccount.MyBookingsPage.bookingDetailsPopup.YouHavepayedForthestay"
                  )
                  : t(
                    "MyAccount.MyBookingsPage.bookingDetailsPopup.YoullPayWhenYouStayAt"
                  )}{" "}
                {bookingData?.hotel_details?.name}
              </div>
              <h4>
                {t(
                  "MyAccount.MyBookingsPage.bookingDetailsPopup.Reservationdetails"
                )}
              </h4>
              <table className="table">
                <tbody>
                  <tr>
                    <td>
                      {t(
                        "MyAccount.MyBookingsPage.bookingDetailsPopup.BookingID"
                      )}
                    </td>
                    <td> {bookingData?.booking_number}</td>
                  </tr>
                  <tr>
                    <td>
                      {t(
                        "MyAccount.MyBookingsPage.bookingDetailsPopup.Checkin"
                      )}{" "}
                    </td>
                    <td>
                      {" "}
                      {moment(new Date(`${bookingData?.checkin_date}`)).format(
                        "ddd, DD MMM, YYYY "
                      )}{" "}
                      ({t("MyAccount.MyBookingsPage.bookingDetailsPopup.from")}{" "}
                      {moment(new Date(`${bookingData?.checkin_date}`))
                        .utc()
                        .format("hh:mm A")}
                      )
                    </td>
                    {/* <td>Monday 2 May 2022 (from 16:00)</td> */}
                  </tr>
                  <tr>
                    <td>
                      {t(
                        "MyAccount.MyBookingsPage.bookingDetailsPopup.Checkout"
                      )}{" "}
                    </td>
                    <td>
                      {" "}
                      {moment(new Date(`${bookingData?.checkout_date}`)).format(
                        "ddd, DD MMM, YYYY "
                      )}{" "}
                      ({t("MyAccount.MyBookingsPage.bookingDetailsPopup.from")}{" "}
                      {moment(new Date(`${bookingData?.checkout_date}`))
                        .utc()
                        .format("hh:mm A")}
                      )
                    </td>
                    {/* <td>Tuesday 3 May 2022 (until 14:00)</td> */}
                  </tr>
                  <tr>
                    <td>
                      {t(
                        "MyAccount.MyBookingsPage.bookingDetailsPopup.Yourreservation"
                      )}
                    </td>
                    {/* <td>1 night, 1 apartment</td> */}
                    <td>
                      {bookingData?.no_of_nights > 0 &&
                        `${bookingData?.no_of_nights} ${bookingData?.no_of_nights > 1
                          ? `${t(
                            "MyAccount.MyBookingsPage.bookingDetailsPopup.Nights"
                          )} ,`
                          : `${t(
                            "MyAccount.MyBookingsPage.bookingDetailsPopup.Night"
                          )} ,`
                        }`}
                      {bookingData?.hours > 0 &&
                        `${bookingData?.hours} ${bookingData?.hours > 1
                          ? `${t(
                            "MyAccount.MyBookingsPage.bookingDetailsPopup.Hours"
                          )} ,`
                          : `${t(
                            "MyAccount.MyBookingsPage.bookingDetailsPopup.Hour"
                          )} ,`
                        }`}

                      {`${bookingData?.no_of_rooms} ${bookingData?.no_of_rooms > 1
                          ? t(
                            "MyAccount.MyBookingsPage.bookingDetailsPopup.Rooms"
                          )
                          : t(
                            "MyAccount.MyBookingsPage.bookingDetailsPopup.Room"
                          )
                        }`}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {t(
                        "MyAccount.MyBookingsPage.bookingDetailsPopup.Youbookedfor"
                      )}
                    </td>

                    <td>
                      {bookingData?.no_of_guests}{" "}
                      {bookingData?.no_of_guests > 1
                        ? t(
                          "MyAccount.MyBookingsPage.bookingDetailsPopup.Guests"
                        )
                        : t(
                          "MyAccount.MyBookingsPage.bookingDetailsPopup.Guest"
                        )}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {t(
                        "MyAccount.MyBookingsPage.bookingDetailsPopup.Location"
                      )}
                    </td>
                    <td>
                      {bookingData?.hotel_details?.address?.address_line1},
                      {bookingData?.hotel_details?.address?.city_village}
                      {bookingData?.hotel_details?.address?.post_office != ""
                        ? ` , ${bookingData?.hotel_details?.address?.post_office}, `
                        : ""}
                      {bookingData?.hotel_details?.address?.state != ""
                        ? `, ${bookingData?.hotel_details?.address?.state}, `
                        : ""}
                      {bookingData?.hotel_details?.address?.country}

                    </td>
                  </tr>

                  <tr>
                    <td>
                      {t(
                        "MyAccount.MyBookingsPage.bookingDetailsPopup.Cancellationpolicy"
                      )}
                    </td>

                    {/* Cancellation Policy condition   */}
                    {
                      bookingData?.cancellation_policy_details.policy_type === '0'
                        ?
                        (
                          <td>
                            <li>
                              {t("MyAccount.MyBookingsPage.bookingDetailsPopup.NonRefundable")}
                            </li>
                          </td>
                        ) :
                        (
                          <td>
                            <li>
                              {t("MyAccount.MyBookingsPage.bookingDetailsPopup.Refundable")}
                            </li>
                          </td>
                        )
                    }
                    {/* Cancellation Policy condition   */}
                  </tr>
                  <tr>
                    <td>
                      {t(
                        "MyAccount.MyBookingsPage.bookingDetailsPopup.Cancellationcost"
                      )}{" "}
                    </td>
                    <td>
                      <ul>
                        {
                          bookingData?.cancellation_policy_details.policy_type === '0' ?
                            (<li>{t("MyAccount.MyBookingsPage.bookingDetailsPopup.NonRefundable")}</li>)
                            :
                            (
                              bookingData?.cancellation_policy_details
                                .cancellation_time == 0 ?
                                (
                                  <li>
                                    {t("MyAccount.MyBookingsPage.bookingDetailsPopup.AnyTimeCacellationCostMessage").replace(new RegExp('##AMOUNT_WITH_CURRENCY##', 'g'), `${bookingData?.cancellation_policy_details?.cancellation_cost_until_date} ${currencyToShow?.current}`)}
                                  </li>
                                )
                                :
                                <>
                                  <li>
                                    {t(
                                      "MyAccount.MyBookingsPage.bookingDetailsPopup.CancellationUntillBeforeMessage"
                                    ).replace(new RegExp('##AMOUNT_WITH_CURRENCY##|ريال', 'g'), `${bookingData?.cancellation_policy_details?.cancellation_cost_until_date} ${currencyToShow?.current}`).replace(new RegExp('##DATE_LIMIT##|#DATE#', 'g'), moment(
                                      new Date(`${bookingData?.cancellation_policy_details?.cancellation_until_date}`),
                                      ["YYYY-MM-DD[T]HH:mm:ss.SSS[Z]"]
                                    ).locale('ar').format('LL LT'))}{" "}
                                  </li>
                                  <li>
                                    {t(
                                      "MyAccount.MyBookingsPage.bookingDetailsPopup.CancellationUntillAfterMessage"
                                    ).replace(new RegExp('##DATE_LIMIT##', 'g'), moment(
                                      new Date(`${bookingData?.cancellation_policy_details?.cancellation_until_date}`),
                                      ["YYYY-MM-DD[T]HH:mm:ss.SSS[Z]"]
                                    ).locale('ar').format('LL LT'))}{" "}
                                  </li>
                                </>
                            )
                        }
                      </ul>
                    </td>
                  </tr>
                </tbody>
              </table>
              <h4>
                {t("MyAccount.MyBookingsPage.bookingDetailsPopup.Pricedetails")}
              </h4>
              {renderPriceBreakup()}

              <h4>{bookingData?.room_type_details?.name}</h4>
              <div
                className="title-sm room_type_details_desc"
                contentEditable="false"
                dangerouslySetInnerHTML={{
                  __html: `${bookingData?.room_type_details?.description}`,
                }}
              ></div>


              <table className="table">
                <tbody>
                  <tr>
                    <td>
                      {t(
                        "MyAccount.MyBookingsPage.bookingDetailsPopup.Maximumcapacity"
                      )}{" "}
                    </td>
                    <td>
                      7 guests maximum, of which 6 adults maximum. Your total
                      price is based on the rate for your booked number of
                      guests (6 adults). Extra prices may apply for additional
                      guests, up to the maximum capacity.
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {t(
                        "MyAccount.MyBookingsPage.bookingDetailsPopup.CheckinTime"
                      )}
                    </td>

                    <td>

                      {moment(new Date(`${bookingData?.checkin_date}`))
                        .utc()
                        .format("hh:mm A")}
                    </td>
                    {/* )} */}
                  </tr>

                  <tr>
                    <td>
                      {t(
                        "MyAccount.MyBookingsPage.bookingDetailsPopup.CheckoutTime"
                      )}
                    </td>

                    <td>

                      {moment(new Date(`${bookingData?.checkout_date}`))
                        .utc()
                        .format("hh:mm A")}
                    </td>
                    {/* )} */}
                  </tr>
                </tbody>
              </table>
            </div>
          </Box>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};

const mapStateToProps = createStructuredSelector({
  currencyToShow: selectcurrencyToShow,
  languageToShow: selectlanguageToShow,
});
export default connect(mapStateToProps)(BookingConfirmationDetailsModal);
