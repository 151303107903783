import React, { useEffect, useState } from "react";
import { Button, Form} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import validator from "validator";
import { connect } from "react-redux";

import { selectUserLoginData } from "../../redux/user/user.selectors";
import {
  addPartnerRequest,
  stateClearAfterTask,
} from "../../redux/useraccount/useraccount.actions";
import { selectAddPartnerData } from "../../redux/useraccount/useraccount.selectors";
import { createStructuredSelector } from "reselect";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import SuccessDialog from "../common/dialogs/SuccessDialog";
import {
  MobileNumberValidator,
  NumberValidator,
  EmailValidator,
} from "../../constants/InputValidator";
import { useTranslation } from "react-i18next";

const PartnersWithWfrleeForm = ({
  addPartnerRequest,
  partnerData,
  stateClear,
}) => {
  const [t] = useTranslation("common");
  const [userData, setUserData] = useState({
    suppliername: "",
    firstname: "",
    lastname: "",
    email: "",
    mobileNo: "",
  });
  const [userDataError, setuserDataError] = useState({
    suppliernameErr: "",
    firstnameErr: "",
    lastnameErr: "",
    emailErr: "",
    mobileNoErr: "",
  });
  const [countryCode, setCountryCode] = React.useState("+966");
  const [countryCodeErr, setCountryCodeErr] = React.useState("");
  const [showSuccessDialog, setShowSuccessDialog] = useState(false);
  const translatedMessageObj = {
    MobileNumberValidatorMsgObj: {
      pleaseEnterYourMobileNumber: t(
        "validatorMsg.pleaseEnterYourMobileNumber"
      ),
      PleaseEnteronlyNumber: t("validatorMsg.PleaseEnteronlyNumber"),
      Atleast10DigitsRequired: t("validatorMsg.Atleast10DigitsRequired"),
      MobileNumberIsInvalid: t("validatorMsg.MobileNumberIsInvalid"),
    },
    EmailValidatorMsgObj: {
      PleaseEnterYourEmail: t("validatorMsg.PleaseEnterYourEmail"),
      PleaseEnteraValidEmailAddress: t(
        "validatorMsg.PleaseEnteraValidEmailAddress"
      ),
    },
    PleaseEnteronlyNumber: t("validatorMsg.PleaseEnteronlyNumber"),
  };

  const navigate = useNavigate();

  /*** Country Code Selection   ***/
  const handleCountryCodeChange = (value, data, event, formattedValue) => {
    if (data.dialCode == "") {
      setCountryCode("");
    } else {
      setCountryCode(formattedValue);
      // setCountryCode("");
      setCountryCodeErr("");
    }
  };

  const handleChange = (e) => {
    if (e.target.name == "suppliername") {
      setuserDataError({
        ...userDataError,
        suppliernameErr: "",
      });
    } else if (e.target.name == "firstname") {
      setuserDataError({
        ...userDataError,
        firstnameErr: "",
      });
    } else if (e.target.name == "lastname") {
      setuserDataError({
        ...userDataError,
        lastnameErr: "",
      });
    } else if (e.target.name == "mobileNo") {
      const isNumberError = MobileNumberValidator(e.target.value,translatedMessageObj.MobileNumberValidatorMsgObj);
      if (isNumberError) {
        setuserDataError({
          ...userDataError,
          mobileNoErr: isNumberError,
        });
      } else {
        setuserDataError({
          ...userDataError,
          mobileNoErr: "",
        });
      }
    } else {
      setuserDataError({
        ...userDataError,
        emailErr: "",
      });
    }
    setUserData({
      ...userData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const regex = "^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$";
    const mobileNoError = MobileNumberValidator(userData.mobileNo,translatedMessageObj.MobileNumberValidatorMsgObj);
    if (userData.suppliername == "") {
      setuserDataError({
        ...userDataError,
        suppliernameErr: t(
          "PartnerswithwfrleePage.form.validationMsg.hotelnameErrMsg"
        ),
      });
      return;
    } else if (userData.firstname == "") {
      setuserDataError({
        ...userDataError,
        firstnameErr: t("PartnerswithwfrleePage.form.validationMsg.nameErrMsg"),
      });
      return;
    } else if (userData.lastname == "") {
      setuserDataError({
        ...userDataError,
        lastnameErr: t(
          "PartnerswithwfrleePage.form.validationMsg.lastnameErrMsg"
        ),
      });
      return;
    } else if (countryCode == "") {
      
      setCountryCodeErr(
        t("PartnerswithwfrleePage.form.validationMsg.counrtycodeErrMsg")
      );
      return;
    } else if (mobileNoError) {
      setuserDataError({
        ...userDataError,
        mobileNoErr: mobileNoError,
      });
      return;
    
    } else if (!validator.isEmail(userData.email)) {
      setuserDataError({
        ...userDataError,
        emailErr: t("PartnerswithwfrleePage.form.validationMsg.emailErrMsg"),
      });
      return;
    } else {
      const data = {
        first_name: userData.firstname,
        last_name: userData.lastname,
        country_code: countryCode,
        contact_number: userData.mobileNo,
        email: userData.email,
      };
      const contact_person = [];
      contact_person.push(data);
      const dataToPost = {
        hotel_name: userData.suppliername,
        contact_person: contact_person,
      };

      addPartnerRequest(dataToPost);
      setUserData({
        suppliername: "",
        firstname: "",
        lastname: "",
        email: "",
        mobileNo: "",
      });
    }
  };
  useEffect(() => {
    if (partnerData != null) {
      if (partnerData.success == true) {
        // navigate("/");
        setShowSuccessDialog(true);
      } else {
      }
      // stateClear();
    }
  }, [JSON.stringify(partnerData)]);

  const handleCloseDialog = () => {
    setShowSuccessDialog(false);
    // navigate(-1);
    stateClear();
  };
  useEffect(() => {
    if (partnerData == null) {
      setShowSuccessDialog(false);
    }
  }, [partnerData]);

  return (
    <>
      <Form onSubmit={handleSubmit} className={"careerForm"}>
        <h5 className="WL_partners_labelboldtext">
          {t("PartnerswithwfrleePage.form.HotelName")} *
        </h5>
        <Form.Group controlId="formPartnersSupplier">
          <Form.Control
            type="text"
            placeholder={t("PartnerswithwfrleePage.form.HotelName")}
            aria-label=""
            onChange={handleChange}
            value={userData.suppliername}
            name="suppliername"
          />
          <Form.Text className="text-muted">
            {userDataError.suppliernameErr}
          </Form.Text>
        </Form.Group>

        <h5 className="WL_partners_labelboldtext Wl_gap20">
          {t("PartnerswithwfrleePage.form.contactPersonDetails.heading")}
        </h5>
        <div className="form50">
          <Form.Group controlId="formPartnersFirstname">
            <Form.Label>
              {t("PartnerswithwfrleePage.form.contactPersonDetails.Name")} *
            </Form.Label>
            <Form.Control
              type="text"
              placeholder={t(
                "PartnerswithwfrleePage.form.contactPersonDetails.FirstName"
              )}
              onChange={handleChange}
              value={userData.firstname}
              name="firstname"
            />
            <Form.Text className="text-muted">
              {userDataError.firstnameErr}
            </Form.Text>
          </Form.Group>
          <Form.Group className="lastname" controlId="formPartnersLastname">
            <Form.Label>
              {t("PartnerswithwfrleePage.form.contactPersonDetails.LastName")} *
            </Form.Label>
            <Form.Control
              type="text"
              placeholder={t(
                "PartnerswithwfrleePage.form.contactPersonDetails.LastName"
              )}
              name="lastname"
              onChange={handleChange}
              value={userData.lastname}
            />
            <Form.Text className="text-muted">
              {userDataError.lastnameErr}
            </Form.Text>
          </Form.Group>
        </div>
        <div className="form50">
          <Form.Group controlId="formPartnersPhone">
            <Form.Label>
              {t(
                "PartnerswithwfrleePage.form.contactPersonDetails.MobileNumberText"
              )}{" "}
              *
            </Form.Label>
            <Form.Group
              controlId="formCheckoutPhone"
              className="phoneWithCountryCode"
            >
              <Form.Group controlId="formCheckoutCountryCode">
                <PhoneInput
                  // defaultCountry="IN"
                  autoFormat={false}
                  enableSearch={true}
                  // country={"in"}
                  excludeCountries={[
                    "cu",
                    "ru",
                    "kp",
                    "ua",
                    "ir",
                    "sd",
                    "ss",
                    "sy",
                  ]}
                  value={countryCode}
                  onChange={handleCountryCodeChange}
                  name="countryCode"
                  placeholder={"+91"}
                  className="checkoutcountryCodeInput"
                />
              </Form.Group>
              <Form.Group
                controlId="formCheckoutPoneNumber"
                className="checkoutPhoneNumber"
              >
                <Form.Control
                  type="text"
                  placeholder={t(
                    "PartnerswithwfrleePage.form.contactPersonDetails.MobileNumber"
                  )}
                  name="mobileNo"
                  value={userData.mobileNo}
                  onChange={handleChange}
                  maxLength={10}
                />
              </Form.Group>
            </Form.Group>
            <Form.Text className="text-muted">
              {userDataError.mobileNoErr} {countryCodeErr}
            </Form.Text>
          </Form.Group>
          <Form.Group controlId="formPartnersEmail">
            <Form.Label>
              {t("PartnerswithwfrleePage.form.contactPersonDetails.Email")} *
            </Form.Label>
            <Form.Control
              type="email"
              placeholder={t(
                "PartnerswithwfrleePage.form.contactPersonDetails.Emailaddress"
              )}
              name="email"
              value={userData.email}
              onChange={handleChange}
            />
            <Form.Text className="text-muted">
              {userDataError.emailErr}
            </Form.Text>
          </Form.Group>
        </div>

        <Button variant="" type="submit" className="formsubmit">
          {t("PartnerswithwfrleePage.form.Send")}
        </Button>
      </Form>

      <SuccessDialog
        setShowSuccessDialog={setShowSuccessDialog}
        isOpen={showSuccessDialog}
        caption={partnerData != null ? partnerData?.message : ""}
        description={<></>}
        cancelButtonLabel={t("PartnerswithwfrleePage.form.Done")}
        clearState={stateClear}
        cancelButtonHandler={handleCloseDialog}
      />
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  partnerData: selectAddPartnerData,
  userAuthData: selectUserLoginData,
});

const mapDispatchToProps = (dispatch) => ({
  addPartnerRequest: (data) => dispatch(addPartnerRequest(data)),
  stateClear: () => dispatch(stateClearAfterTask()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PartnersWithWfrleeForm);
