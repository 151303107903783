import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const CMSLeftMenu = ({ location }) => {
  const [t] = useTranslation("common");
  return (
    <>
      <ul className="leftmenu">
      <li className={location.pathname == "/faq" ? "active" : ""}>
          <Link to="/faq">{t("CMSLeftMenu.FAQ")}</Link>
        </li>
   
        <li className={location.pathname == "/aboutus" ? "active" : ""}>
          <Link to="/aboutus">{t("CMSLeftMenu.Aboutus")}</Link>
        </li>
        <li className={location.pathname == "/privacy-policy" ? "active" : ""}>
          <Link to="/privacy-policy">{t("CMSLeftMenu.PrivacyPolicy")}</Link>
        </li>
        <li className={location.pathname == "/rating-and-review" ? "active" : ""}>
          <Link to="/rating-and-review">{t("CMSLeftMenu.ratingandreview")}</Link>
        </li>
        <li className={location.pathname == "/safety-security" ? "active" : ""}>
          <Link to="/safety-security">
            {t("CMSLeftMenu.SafetyandSecurity")}
          </Link>
        </li>
        <li
          className={location.pathname == "/terms&conditions" ? "active" : ""}
        >
          <Link to="/terms&conditions">
            {t("CMSLeftMenu.TermsandConditions")}{" "}
          </Link>
        </li>
        <li className={location.pathname == "/offers" ? "active" : ""}>
          <Link to="/offers">{t("CMSLeftMenu.OffersandDiscounts")}</Link>
        </li>
        <li
          className={location.pathname == "/partnerswithwfrlee" ? "active" : ""}
        >
          <Link to="/partnerswithwfrlee">
            {t("CMSLeftMenu.Partnerswithwfrleecom")}
          </Link>
        </li>
      
        <li className={location.pathname == "/referral" ? "active" : ""}>
          <Link to="/referral">{t("CMSLeftMenu.Referral")}</Link>
        </li>
        <li className={location.pathname == "/contactus" ? "active" : ""}>
          <Link to="/contactus">{t("CMSLeftMenu.Contactus")}</Link>
        </li>
        <li className={location.pathname == "/career" ? "active" : ""}>
          <Link to="/career">{t("CMSLeftMenu.Careers")}</Link>
        </li>
        <li>
          <Link target="_blank" to="https://blog.wfrlee.com/">{t("footer.Company.Blog")}</Link>
        </li>
      </ul>
    </>
  );
};

export default CMSLeftMenu;
