import { takeLatest, put, all, call } from "redux-saga/effects";
import axios from "axios";
import FileUploadActionTypes from "./fileUpload.types";

import {
  errorToast,
  successToast,
  warningToast,
} from "../../utils/toastHelper";

import {
  careerFileUploadSuccess,
  careerFileUploadFailed,
} from "../common/fileUpload.actions";
import store from "./../store";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
  const MySwal = withReactContent(Swal);
/***ADD CAREER SAGA ASYNC * */
var backendUrlEndPoint = process.env.REACT_APP_BACKEND_API_ENDPOINT;
export function* careerFileUploadAsync({ payload: data }) {
  try {
    const fileUploadResponse = yield axios.post(
      `${backendUrlEndPoint}/common/upload-carrer-document`,
      data,
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "multipart/form-data",
          mobile: true,
          "Accept-Language": `${store.getState().language.language}`,
        },
      }
    );

    if (fileUploadResponse.data.success) {
     // successToast("File uploaded successfully");
     MySwal.fire({
      confirmButtonText: 'OK',
      confirmButtonColor: '#296da9',
       text:fileUploadResponse.data.message
      }); 
      yield put(careerFileUploadSuccess(fileUploadResponse.data));
    } else {
      MySwal.fire({
        confirmButtonText: 'OK',
        confirmButtonColor: '#296da9',
         text:fileUploadResponse.data.message
        }); 
     // errorToast(fileUploadResponse.data.message);
      yield put(careerFileUploadFailed(fileUploadResponse.data));
    }
  } 
  catch (error) {
    // console.log(error.message);
  }
}

export function* careerFileUploadFetchStart() {
  yield takeLatest(
    FileUploadActionTypes.CAREER_FILE_UPLOAD_REQUEST,
    careerFileUploadAsync
  );
}

export function* fileUploadSagas() {
  yield all([call(careerFileUploadFetchStart)]);
}
