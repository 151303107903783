import React, { useEffect } from "react";
import { requestSocialLogin } from "../../redux/user/user.actions";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { useNavigate, useLocation } from "react-router-dom";
import { getUserFromLocalStorage } from "../../config/common.api";
import Config from "../../constants/Config.env";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import AppleSignin from "react-apple-signin-auth";
import { GoogleLogin, useGoogleLogin } from "@react-oauth/google";
import axios from "axios";

import {
  selectSocialLoginUser,
  selectSaveGuestUserCheckoutData,
} from "../../redux/user/user.selectors";

const SocialLogin = ({
  requestSocialLogin,
  userData,
  saveGuestUserCheckoutData,
  handleCloseLoginPopup,
  search_type,
}) => {

  const navigate = useNavigate();
  const user = getUserFromLocalStorage();
  const location = useLocation();
  const currentLocation = useLocation();
  const [users, setUsers] = React.useState([]);
  const englishAlphabetsRegex = /^[A-Za-z\s]+$/;
  const facebookLogin = async (data) => {
    if (data?.email && englishAlphabetsRegex.test(data.name)) {
      const splitFullname = data.name.split(" ");
      const postData = {
        first_name: splitFullname[0],
        last_name: splitFullname[1],
        email: data.email,
        social_platform: "facebook",
        social_login_id: data.id,
        social_login_token: data.accessToken,
      };
      requestSocialLogin(postData);
    } else {
      window.alert("Your Name should only Contain English Alphabets! Please Try Again.")
    }
  };
  
  const googleLogin = async (credentialResponse) => {
    if (
      credentialResponse?.profileObj?.email &&
      englishAlphabetsRegex.test(credentialResponse?.profileObj.name)
    ) {
      const splitFullname = credentialResponse?.profileObj.name.split(" ");
      const postData = {
        first_name: splitFullname[0],
        last_name: splitFullname[1],
        email: credentialResponse?.profileObj.email,
        social_platform: "google",
        social_login_id: credentialResponse.googleId,
        social_login_token: credentialResponse.tokenId,
      };


      requestSocialLogin(postData);
    } else {
      window.alert("Your Name should only Contain English Alphabets! Please Try Again.")
    }
  };
  
  const appleLoginClick = (data) => {
    if (data.hasOwnProperty("user")) {
      const englishAlphabetsRegex = /^[a-zA-Z ]*$/;
      if (
        englishAlphabetsRegex.test(data.user.name.firstName) &&
        englishAlphabetsRegex.test(data.user.name.lastName)
      ) {
        const postData = {
          first_name: data.user.name.firstName,
          last_name: data.user.name.lastName,
          social_platform: "apple",
          social_login_token: data.authorization.id_token,
        };
        requestSocialLogin(postData);
      } else {
        window.alert(
          "Your name should only contain English alphabets! Please try again."
        );
      }
    } else {
      const postData = {
        social_platform: "apple",
        social_login_token: data.authorization.id_token,
      };
      requestSocialLogin(postData);
    }
  };
  

  useEffect(() => {
    if (userData != null) {
      if (userData.success == true) {
        if (currentLocation.pathname == "/login") {
          navigate("/");
        } else {
          handleCloseLoginPopup();
        }

      } else {
      }
    }
  }, [JSON.stringify(userData)]);

  const login = useGoogleLogin({
    onSuccess: (users) => {
      setUsers(users);
      GoogleLogin(users);
    },
  });

  const GoogleLogin = (users) => {
    axios
      .get(
        `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${users.access_token}`,
        {
          headers: {
            Authorization: `Bearer ${users.access_token}`,
            Accept: "application/json",
          },
        }
      )
      .then((res) => {
        const englishAlphabetsRegex = /^[a-zA-Z ]*$/;
        if (
          englishAlphabetsRegex.test(res.data.name) &&
          res.data.name.trim() !== ""
        ) {
          const splitFullname = res.data.name.split(" ");
          const postData = {
            first_name: splitFullname[0],
            last_name: splitFullname[1],
            email: res.data.email,
            social_platform: "google",
            social_login_id: res.data.id,
            social_login_token: users.access_token,
          };
          requestSocialLogin(postData);
          setUsers("");
        } else {
          window.alert(
            "Your name should only contain English alphabets! Please try again."
          );
        }
      })
      .catch((err) => {
        // console.log(err);
      });
  };
  

  return (
    <>
      <div className="social-log">
        <ul>
          <li>
            
            <button
              className="SignIn-Social SignIn-google"
              onClick={() => login()}
            >
              <img src="./img/google-log.svg" alt="" />
            </button>
            
          </li>
          <li>
            <FacebookLogin
              appId={Config.FB_APP_ID}
              authType="rerequest"
              fields="name,email,picture"
              callback={facebookLogin}
              scope="public_profile,email"
              render={(renderProps) => (
                <button
                  className="SignIn-Social SignIn-facebook"
                  onClick={() => renderProps.onClick()}
                  disabled={renderProps.isDisabled}
                >
                  <img src="./img/facebook-log.svg" alt="" />
                </button>
              )}
            />
          </li>
         
          <li>
            <AppleSignin
              /** Auth options passed to AppleID.auth.init() */
              authOptions={{
                clientId: Config.Apple_ClientID,
                scope: "email name",
                redirectURI: Config.Apple_RedirectURI,
                state: "",
                nonce: "nonce",
                usePopup: true,
              }}
              /** General props */
              uiType="light"
              /** className */
              className="SignIn-Social SignIn-apple"
              /** Allows to change the button's children, eg: for changing the button text or icon */
              buttonExtraChildren={
                <>
                  <img src="./img/apple-log.svg" alt="" />
                </>
              }
              onSuccess={(response) => appleLoginClick(response)}
              onError={(error) => console.error("APPPLEErrorresponse", error)} //Called upon signin error
            />
          </li>
        </ul>
      </div>
    </>
  );
};
const mapStateToProps = createStructuredSelector({
  userData: selectSocialLoginUser,
  saveGuestUserCheckoutData: selectSaveGuestUserCheckoutData,
});
const mapDispatchToProps = (dispatch) => ({
  requestSocialLogin: (data) => dispatch(requestSocialLogin(data)),
});
export default connect(mapStateToProps, mapDispatchToProps)(SocialLogin);
