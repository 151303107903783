import React from 'react';
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import DialogContent from "@mui/material/DialogContent";
import { useConfirmDialogStyles } from "./../../components/common/dialogs/ConfirmDialogStyles";
import DialogTitle from "@mui/material/DialogTitle";
import CancelIcon from '@mui/icons-material/Cancel';
import {  useSearchParams ,  useNavigate} from 'react-router-dom';
import moment from 'moment';

const BiddingFailed = () => {
  const navigate = useNavigate();
    const classes = useConfirmDialogStyles();
    let [searchParams] =  useSearchParams();
    const msg = searchParams.get('msg');
    const hotel_id = searchParams.get('hotel_id');
    const room_type_id = searchParams.get('room_type_id');
    const check_in_date =  moment.utc(new Date(searchParams.get('check_in_date'))).format('DD/MM/YYYY');
    const check_out_date = moment.utc(new Date(searchParams.get('check_out_date'))).format('DD/MM/YYYY');
    const adults = searchParams.get('adults');
    const children = searchParams.get('children');
    const no_od_rooms = searchParams.get('no_of_rooms');
    const language = searchParams.get('language');
    const numberOfAttemptsRemaining = searchParams.get('back_to_bid_again_button_to_show');
  


    return (
 

            <React.Fragment>
            <div className='conformedpop'>
          <div  className={classes.dialogPopup} >
                          <DialogTitle sx={{ pb: 3 }}>
            <Box sx={{ position: "absolute", right: 0, left: "auto", top: 0 }}>
            </Box>
          </DialogTitle>
                      <DialogContent className={classes.dialogContent}>
                      <Box className={classes.dialogText}>
                        <Box className={classes.checkIconFailure }>
                        <CancelIcon /> 
                        </Box>
                        <Typography className={classes.heading}>{ language === "EN" ? "Bidding Failed" : "العطاء فشل" }</Typography>
                      <Typography className={classes.heading}>{ msg}</Typography>
                  </Box>
                    </DialogContent>
                    
                      <div style={{ display: "flex",justifyContent: "center",flexDirection: "row",padding: "20px 40px !important",gap:"5px"}} >
                        
                      { numberOfAttemptsRemaining === 1 ? 
                    <Button className={classes.noBtn} onClick={() => { navigate("/bidguestuser",{ state: {"hotel_id":hotel_id,"room_type_id":room_type_id,"check_in_date":check_in_date,"check_out_date":check_out_date,"adults":adults,"children":children,"rooms":no_od_rooms}})}} >
                      {language === "EN" ? "Back to bid again " : "العودة لتقديم العطاء مرة أخرى"}
                    </Button>
                      :  
                    <Button className={classes.noBtn} onClick={() => { navigate("/")}} >
                      {language === "EN" ? "Go to homepage" : "العودة للصفحة الرئيسية"}
                    </Button>

                    }

                     
                      </div>
                      
                   
                    
                    </div>
               </div>
 
        </React.Fragment>
                    
                   
        

        
        )
}



export default (BiddingFailed);