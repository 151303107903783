const UserAccountActionTypes = {
  SET_MY_PROFILE_CURRENT_USER_DATA_START:
    "SET_MY_PROFILE_CURRENT_USER_DATA_START",
  SET_MY_PROFILE_CURRENT_USER_DATA_SUCCESS:
    "SET_MY_PROFILE_CURRENT_USER_DATA_SUCCESS",
  SET_MY_PROFILE_CURRENT_USER_DATA_FAILURE:
    "SET_MY_PROFILE_CURRENT_USER_DATA_FAILURE",
  BIDING_GET_DATA_REQUEST: "BIDING_GET_DATA_REQUEST",
  BIDING_GET_DATA_SUCCESS: "BIDING_GET_DATA_SUCCESS",
  BIDING_GET_DATA_FAILED: "BIDING_GET_DATA_FAILED",

  ADD_CAREER_REQUEST: "ADD_CAREER_REQUEST",
  ADD_CAREER_SUCCESS: "ADD_CAREER_SUCCESS",
  ADD_CAREER_FAILED: "ADD_CAREER_FAILED",
  STATE_CLEAR_AFTER_TASK: "STATE_CLEAR_AFTER_TASK",

  ADD_PARTNER_REQUEST: "ADD_PARTNER_REQUEST",
  ADD_PARTNER_SUCCESS: "ADD_PARTNER_SUCCESS",
  ADD_PARTNER_FAILED: "ADD_PARTNER_FAILED",
  /** Referrals */
  GET_MY_REFERRALS_DETAILS_REQUEST: "GET_MY_REFERRALS_DETAILS_REQUEST",
  GET_MY_REFERRALS_DETAILS_SUCCESS: "GET_MY_REFERRALS_DETAILS_SUCCESS",
  GET_MY_REFERRALS_DETAILS_FAILED: "GET_MY_REFERRALS_DETAILS_FAILED",

  GET_MY_REFERRALS_LIST_REQUEST: "GET_MY_REFERRALS_LIST_REQUEST",
  GET_MY_REFERRALS_LIST_SUCCESS: "GET_MY_REFERRALS_LIST_SUCCESS",
  GET_MY_REFERRALS_LIST_FAILED: "GET_MY_REFERRALS_LIST_FAILED",

  /** Wallet */
  GET_MY_WALLET_POINTS_REQUEST: "GET_MY_WALLET_POINTS_REQUEST",
  GET_MY_WALLET_POINTS_SUCCESS: "GET_MY_WALLET_POINTS_SUCCESS",
  GET_MY_WALLET_POINTS_FAILED: "GET_MY_WALLET_POINTS_FAILED",

  GET_MY_WALLET_LIST_REQUEST: "GET_MY_WALLET_LIST_REQUEST",
  GET_MY_WALLET_LIST_SUCCESS: "GET_MY_WALLET_LIST_SUCCESS",
  GET_MY_WALLET_LIST_FAILED: "GET_MY_WALLET_LIST_FAILED",

  GET_ALL_LOYALTY_POINTS_REQUEST: "GET_ALL_LOYALTY_POINTS_REQUEST",
  GET_ALL_LOYALTY_POINTS_SUCCESS: "GET_ALL_LOYALTY_POINTS_SUCCESS",
  GET_ALL_LOYALTY_POINTS_FAILED: "GET_ALL_LOYALTY_POINTS_FAILED",

  BUY_WALLET_POINTS_REQUEST: "BUY_WALLET_POINTS_REQUEST",
  BUY_WALLET_POINTS_SUCCESS: "BUY_WALLET_POINTS_SUCCESS",
  BUY_WALLET_POINTS_FAILED: "BUY_WALLET_POINTS_FAILED",

  /** Notifications */
  GET_ALL_NOTIFICATIONS_REQUEST: "GET_ALL_NOTIFICATIONS_REQUEST",
  GET_ALL_NOTIFICATIONS_SUCCESS: "GET_ALL_NOTIFICATIONS_SUCCESS",
  GET_ALL_NOTIFICATIONS_FAILED: "GET_ALL_NOTIFICATIONS_FAILED",

  /*********** CANCEL BOOKING **********/
  CANCEL_BOOKING_REQUEST: "CANCEL_BOOKING_REQUEST",
  CANCEL_BOOKING_SUCCESS: "CANCEL_BOOKING_SUCCESS",
  CANCEL_BOOKING_FAILED: "CANCEL_BOOKING_FAILED",

  /********* CONTACT-US *********/
  ADD_CONTACT_US_REQUEST: "ADD_CONTACT_US_REQUEST",
  ADD_CONTACT_US_SUCCESS: "ADD_CONTACT_US_SUCCESS",
  ADD_CONTACT_US_FAILED: "ADD_CONTACT_US_FAILED",
};

export default UserAccountActionTypes;
