import { createSelector } from "reselect";

const selectFileUpload = (state) => state.fileUpload;

export const selectFileUploadData = createSelector(
  [selectFileUpload],
  (fileUpload) => fileUpload.fileData
);

export const selectFileLoading = createSelector(
  [selectFileUpload],
  (fileUpload) => fileUpload.fileLoading
);
