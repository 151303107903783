const Config = {
  BACKEND_API_ENDPOINT: process.env.REACT_APP_BACKEND_API_ENDPOINT || "",
  FB_APP_ID:
    process.env.NODE_ENV == "production"
      ? process.env.REACT_APP_FB_APP_ID
      : process.env.REACT_APP_FB_APP_ID_LOCAL,
  Google_clientID: 
    process.env.NODE_ENV == "production"
      ? process.env.REACT_APP_GOOGLE_CLIENTID
      : process.env.REACT_APP_GOOGLE_CLIENTID_LOCAL,
  Google_API_key: process.env.REACT_APP_Google_API_key,
  Apple_ClientID: process.env.REACT_APP_Apple_ClientID,
  RedirectURI: process.env.REACT_APP_RedirectURI,
  Apple_RedirectURI: process.env.REACT_APP_Apple_RedirectURI,
  Apple_Scope: process.env.REACT_APP_Apple_Scope,
  Apple_State: process.env.REACT_APP_Apple_State,
};

export default Config;
