import FileUploadActionTypes from "./fileUpload.types";

const INITIAL_STATE = {
  pending: false,
  success: false,
  //   fileList: [],
  fileData: null,
  error: null,
  fileLoading: false,
};

export const FilesUploadReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FileUploadActionTypes.CAREER_FILE_UPLOAD_REQUEST:
      return {
        ...state,
        pending: true,
        success: false,
        fileData: action.payload,
        fileLoading: true,
      };
    case FileUploadActionTypes.CAREER_FILE_UPLOAD_SUCCESS:
      return {
        fileLoading: false,
        pending: false,
        success: true,
        fileData: action.payload,
      };

    case FileUploadActionTypes.CAREER_FILE_UPLOAD_FAILED:
      return {
        ...state,
        fileLoading: false,
        pending: false,
        success: false,
        error: action.payload,
      };

    /******** Toggle Filter add or not ********/

    case FileUploadActionTypes.RESET_FILE_STORE:
      return {
        ...state,
        error: null,
        fileData: null,
        fileLoading: false,
      };

    default:
      return state;
  }
};

export default FilesUploadReducer;
