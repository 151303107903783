import React from "react";
import { Modal } from "react-bootstrap";
import wfrleeLogo from "../../assets/images/logo.svg";
import { Link } from "react-router-dom";
import moment from "moment";
import { useTranslation } from "react-i18next";

const OffersDetailsModal = ({ show, handleClose, offersData, outlet }) => {
  const [t] = useTranslation("common");

  return (
    <Modal
      dialogClassName="modal-lg gen-modal infoModal"
      show={show}
      onHide={handleClose}
    >
      <Modal.Header closeButton>

        <div className="logo">
          <Link to="/">
            <img src={wfrleeLogo} alt="" />
          </Link>
        </div>
      </Modal.Header>
      <Modal.Body className="p-0">
        <div className="offer_banner ">
          <div>
            <p>
              {" "}
              {t("OffersPage.Getupto")} {offersData?.discount_amount}
              {offersData?.discount_type == "percentage"
                ? "%"
                : " " + offersData?.discount_type}{" "}
              {t("OffersPage.OFFonyour")}{" "}
              {offersData?.no_of_time_used == 0 ? t("OffersPage.first") : ""}{" "}
              {t("OffersPage.hotelbooking")}
            </p>
            <p></p>
          </div>
          <img src={offersData?.image} alt="" />
        </div>

        <div className="pop-info-content p-5">
          {offersData?.hotels != null ? (
            offersData?.hotels.length > 0 ? (
              <div>
                {offersData?.hotels[0] !== "Applicable for all hotels" && <p className="pb-0">Applicable for below hotels</p>}
                {offersData?.hotels.map((hotel, index) => {
                  return (
                    <span key={index} className="applicableForAllHotel">
                      {hotel == "Applicable for all hotels"
                        ? hotel
                        : hotel.name}
                      {offersData?.hotels.length > 0 &&
                        offersData?.hotels.length - 1 != index
                        ? ", "
                        : ""}
                    </span>
                  );
                })}
              </div>
            ) : (
              ""
            )
          ) : (
            ""
          )}

          <div className="d-flex gap-1 offer_details_text">
            <p> {t("OffersPage.CouponName")}</p>
            <p>{offersData?.name}</p>
          </div>
          <div className="d-flex gap-1 offer_details_text">
            <p>{t("OffersPage.CouponCode")}</p>
            {outlet}
          </div>

          <div className="d-flex gap-1 offer_details_text">
            <p>{t("OffersPage.Couponvalidfrom")} </p>
            <p>

              {offersData?.valid_from != null
                ? moment(new Date(`${offersData?.valid_from}`)).format(
                  "ddd, DD MMM, YYYY "
                )
                : ""}{" "}
              <span style={{ fontWeight: "400" }}>{t("OffersPage.to")}</span>{" "}
              {offersData?.valid_till != null
                ? moment(new Date(`${offersData?.valid_till}`)).format(
                  "ddd, DD MMM, YYYY "
                )
                : ""}

            </p>
          </div>
          <div className="d-flex gap-1 offer_details_text">
            <p>{t("OffersPage.For")}</p>
            <p className="text-capitalize">
              {offersData?.isFor} {t("OffersPage.user")}
            </p>
          </div>
          <div className="d-flex gap-1 offer_details_text">
            <p>{t("OffersPage.Couponmaximumcanuse")}</p>
            <p>
              {offersData?.maximum_use} {t("OffersPage.times")}
            </p>
          </div>
          <div className="d-flex gap-1 offer_details_text">
            <p>{t("OffersPage.Rules")}</p>
            <p
              className="offer_description"
              contentEditable="false"
              dangerouslySetInnerHTML={{
                __html: `${offersData != null ? offersData?.rules : null}`,
              }}
            ></p>
          </div>

        </div>
      </Modal.Body>
    </Modal>
  );
};
export default OffersDetailsModal;
