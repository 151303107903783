import moment from "moment";
import validator from "validator";

export const TimeFormatValidator = (value) => {
  const inputVal = value.replace(/ /g, ""); //remove all the empty spaces in the input
  let inputNumbersOnly = inputVal.replace(/\D/g, ""); // Get only digits

  if (inputNumbersOnly.length > 5) {
    //If entered value has a length greater than 5 then take only the first 5 digits
    inputNumbersOnly = inputNumbersOnly.substr(0, 5);
  }

  // /^(1[0-2]|0?[1-9]):[0-5][0-9]/
  const splits = inputNumbersOnly.match(/.{1,2}/g);

  let columnedNumber = "";
  if (splits) {
    columnedNumber = splits.join(":"); // Join all the splits with colon
  }

  return columnedNumber;
};

export const CardNumberFormatValidator = (value) => {
  const inputVal = value.replace(/ /g, ""); //remove all the empty spaces in the input
  let inputNumbersOnly = inputVal.replace(/\D/g, ""); // Get only digits

  if (inputNumbersOnly.length > 16) {
    //If entered value has a length greater than 16 then take only the first 16 digits
    inputNumbersOnly = inputNumbersOnly.substr(0, 16);
  }

  // Get nd array of 4 digits per an element EX: ["4242", "4242", ...]
  const splits = inputNumbersOnly.match(/.{1,4}/g);

  let spacedNumber = "";
  if (splits) {
    spacedNumber = splits.join(" "); // Join all the splits with an empty space
  }
  return spacedNumber;
};

export const StringValidator = () => {};

export const NumberValidator = (value, translatedMessageObj) => {
  if (!validator.isNumeric(value)) {
    return `${translatedMessageObj.PleaseEnteronlyNumber}`;
  }
  return "";
};

export const CardExpiryFormatValidator = (value) => {
  let textTemp = value;
  if (textTemp[0] !== "1" && textTemp[0] !== "0") {
    textTemp = "";
  }
  if (textTemp.length === 2) {
    if (
      parseInt(textTemp.substring(0, 2)) > 12 ||
      parseInt(textTemp.substring(0, 2)) == 0
    ) {
      textTemp = textTemp[0];
    } else if (textTemp.length === 2) {
      textTemp += "/";
    } else {
      textTemp = textTemp[0];
    }
  }
  return textTemp;
};

export const MobileNumberValidator = (value, translatedMessageObj) => {
  var pattern = new RegExp(/^[0-9\b\+\-\(\)]+$/);

  if (value == "") {
    return `${translatedMessageObj.pleaseEnterYourMobileNumber}`;
  }
  if (!validator.isNumeric(value)) {
    return `${translatedMessageObj.PleaseEnteronlyNumber}`;
  }
  if (value.length < 10 || value.length > 10) {
    return `${translatedMessageObj.Atleast10DigitsRequired}`;
  }
  if (!pattern.test(value)) {
    return `${translatedMessageObj.MobileNumberIsInvalid}`;
  }
  return "";
};

export const EmailValidator = (value, translatedMessageObj) => {
  if (value == "") {
    return `${translatedMessageObj.PleaseEnterYourEmail}`;
  }
  if (!validator.isEmail(value)) {
    return `${translatedMessageObj.PleaseEnteraValidEmailAddress}`;
  }
  return "";
};
