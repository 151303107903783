import React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { useConfirmDialogStyles } from "./ConfirmDialogStyles";
import DialogTitle from "@mui/material/DialogTitle";
import { GrClose } from "react-icons/gr";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

const ConfirmDialog = ({
  caption,
  description,
  setShowConfirmDialog,
  confirmButtonLabel,
  continueButtonLabel,
  confirmButtonHandler,
  cancelButtonHandler,
  isOpen,
  isConfirmed,
}) => {
  const classes = useConfirmDialogStyles();
  const [open, setOpen] = React.useState(isOpen);

  React.useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const handleClickYes = () => {
    setOpen(false);
    setShowConfirmDialog(false);
    confirmButtonHandler();
  };

  return (
    <React.Fragment>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={() => {
          setShowConfirmDialog(false);
          setOpen(false);
        }}
        aria-labelledby="responsive-dialog-title"
        className={classes.dialogPopup}
      >
        <DialogTitle sx={{ pb: 3 }}>
          <Box sx={{ position: "absolute", right: 0, left: "auto", top: 0 }}>
            <Button
              className={"popclose onClickBtn"}
              
              onClick={cancelButtonHandler}
            >
              <GrClose />
            </Button>
          </Box>
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <Box className={classes.dialogText}>
            <Box className={classes.checkIcon}>
              <CheckCircleOutlineIcon />
            </Box>
            <Typography className={classes.heading}>{caption}</Typography>

            <Box className={classes.text}>{description}</Box>
          </Box>
        </DialogContent>
        <DialogActions className={classes.buttonBox}>
          {isConfirmed === true ? (
            <Button className={classes.yesBtn} onClick={handleClickYes}>
              {confirmButtonLabel}
            </Button>
          ) : (
            ""
          )}
          <Button className={classes.noBtn} onClick={cancelButtonHandler}>
            {continueButtonLabel}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default ConfirmDialog;
