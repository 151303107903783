import { combineReducers } from "redux";
import userReducer from "./user/user.reducer";
import cmsReducer from "./cms/cms.reducer";
import useraccountReducer from "./useraccount/useraccount.reducer";
import lanaguaeReducer from "./language/language.reducer";
import currencyReducer from "./currency/currency.reducer";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
// import SocialLoginReducer from "./Reducers/socialLogin.reducer";
import hotelReducer from "./hotels/hotel.reducer";
import FilesUploadReducer from "./common/fileUpload.reducer";
import faqReducer from "./faq/faq.reducer";
import homeReducer from "./home/home.reducer";
import offerReducer from "./offers/offer.reducer";

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["user", "language"],
  // whitelist: ["hotel"],
};

const rootReducer = combineReducers({
  user: userReducer,
  cms: cmsReducer,
  language: lanaguaeReducer,
  currency: currencyReducer,
  // socialUser:SocialLoginReducer,
  userAccount: useraccountReducer,
  hotel: hotelReducer,
  fileUpload: FilesUploadReducer,
  faq: faqReducer,
  home: homeReducer,
  offer: offerReducer,
});

export default persistReducer(persistConfig, rootReducer);
