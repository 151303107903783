import  CmsActionTypes  from './cms.types';


export const cmsGetDataStart = userCredentials => ({
type:CmsActionTypes.ABOUT_US_GET_DATA_START,
payload:userCredentials
}); 
    
export const cmsGetDataSuccess = (data) => ({
type:CmsActionTypes.ABOUT_US_GET_DATA_SUCCESS,
payload:data
});
    
export const cmsGetDataFailure = error => ({
type:CmsActionTypes.ABOUT_US_GET_DATA_FAILURE,
payload:error
});



