import { takeLatest, put, all, call } from "redux-saga/effects";
import axios from "axios";
import HomeActionTypes from "./home.types";

import {
    errorToast,
    successToast,
    warningToast,
} from "../../utils/toastHelper";
import {  } from "./home.actions";
import { 
getOfferPromotionsPartnersPreferenceSuccess, 
getOfferPromotionsPartnersPreferenceFailure,
getHomePageRandomReviewSuccess, 
getHomePageRandomReviewFailure } from "./home.actions";
var backendUrlEndPoint = process.env.REACT_APP_BACKEND_API_ENDPOINT;
/************ Home Offer Promotions Partners Preference    *************** */
export function* getOfferPromotionsPartnersPreferenceAsync({ payload: data }) {
 
  try {
      const homeResponse = yield axios.get(
        `${backendUrlEndPoint}/common/get-homepage-data`,
        {
          headers: {
            mobile: "true",
            "Accept-Language": `${data}`,
          },
        }
      );
  
      if (homeResponse.status === 200) {
        yield put(getOfferPromotionsPartnersPreferenceSuccess(homeResponse.data.data));
      } else {
        yield put(getOfferPromotionsPartnersPreferenceFailure(homeResponse.data));
      }
    } 
    catch (error) {
      // console.log("Network Error",error);
    }
}
/************ Home Offer Promotions Partners Preference    *************** */

/******* HOME PAGE RANDOM REVIEW SAGA   ********/
export function* getRandomAPIcallReviewDataAsync({ payload: data }) {
  try {
    const homeResponse = yield axios.get(
      `${backendUrlEndPoint}/hotel/random-rating-review-list`,
      {
        headers: {
          mobile: "true",
          "Accept-Language": `${data}`,
        },
      }
    );

    if (homeResponse.status === 200) {
      yield put(getHomePageRandomReviewSuccess(homeResponse.data.data));
    } else {
      yield put(getHomePageRandomReviewFailure(homeResponse.data));
    }
  } catch (error) {
    // console.log("Network Error",error);
  }
}
/******* HOME PAGE RANDOM REVIEW SAGA   ********/

export function* homeOfferPromotionsPartnersPreferenStart() {
 yield takeLatest(
  HomeActionTypes.GET_OFFER_PROMOTIONS_PARTNERS_PREFERENCE_DATA_REQUEST,
  getOfferPromotionsPartnersPreferenceAsync
 );
}
  
export function* homeRandomReviewStart() {
  yield takeLatest(
    HomeActionTypes.GET_HOME_PAGE_RANDOM_REVIEW_REQUEST,
    getRandomAPIcallReviewDataAsync
   );  
}


export function* homeSagas() {
yield all([
call(homeOfferPromotionsPartnersPreferenStart),
call(homeRandomReviewStart)
]);
}  