import React from "react";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

const MyAccountLeftMenu = () => {
  const location = useLocation();
  const [t] = useTranslation("common");
  return (
    <ul>
      <li className={location.pathname == "/myaccount" ? "active" : ""}>
        <Link to="/myaccount">{t("MyAccount.MyProfilePage.heading")}</Link>
      </li>
      <li className={location.pathname == "/mybooking" ? "active" : ""}>
        <Link to="/mybooking">{t("MyAccount.MyBookingsPage.heading")}</Link>
      </li>
      <li className={location.pathname == "/mybidding" ? "active" : ""}>
        <Link to="/mybidding">{t("MyAccount.MyBiddingsPage.heading")}</Link>
      </li>
      <li className={location.pathname == "/favorites" ? "active" : ""}>
        <Link to="/favorites">{t("MyAccount.MyFavouritesPage.heading")}</Link>
      </li>
      <li className={location.pathname == "/mypoints" ? "active" : ""}>
        <Link to="/mypoints">{t("MyAccount.MyPointsPage.heading")}</Link>
      </li>
      <li className={location.pathname == "/mywallet" ? "active" : ""}>
        <Link to="/mywallet">{t("MyAccount.MyWalletPage.heading")}</Link>
      </li>
      <li className={location.pathname == "/myreferrals" ? "active" : ""}>
        <Link to="/myreferrals">{t("MyAccount.MyReferralsPage.heading")}</Link>
      </li>
      <li className={location.pathname == "/notification" ? "active" : ""}>
        <Link to="/notification">
          {t("MyAccount.NotificationsPage.heading")}
        </Link>
      </li>
    </ul>
  );
};

export default MyAccountLeftMenu;
