import React, {  useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";
import { selectLanguage } from "./../../redux/language/language.actions";
import { selectlanguageToShow } from "./../../redux/language/language.selectors";
import { getFullHotelDetailsRequest, placeStateDataSave, hotelRouteDetailsStateHandler } from "./../../redux/hotels/hotel.actions";
import { selectHotelData, selectHotelBidNowPageRouteData, selectHotelDetailsPageRouteData  } from "./../../redux/hotels/hotel.selectors";
import { selectUserLoginData } from "../../redux/user/user.selectors";
import { useParams } from "react-router-dom"
import HotelListingDetails from "../../components/Hotels/HotelListingDetails";
import { Helmet } from "react-helmet";
import {
  useSearchParams,
  useLocation,
} from "react-router-dom";
const ListingdetailsPage = ({
  getFullHotelDetailsRequest,
  selectLanguage,
  languageToShow,
  selectHotelData,
  userAuthData,
  placeStateDataSave,
  hotelRouteDetailsStateHandler,
  selectHotelDetailsPageRouteData,
  selectHotelBidNowPageRouteData
}) => {
  const { slug } = useParams();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const data = {
    hotel_slug: slug,
    languageToShow: languageToShow,
    token: userAuthData != null ? userAuthData.token : "",
  };
  React.useEffect(() => {
    if (searchParams.get("search_type") == "bid") {
      const searchedParams = {
        search_type: searchParams.get("search_type"),
        city: searchParams?.get("city"),
        check_in_date: searchParams.get("check_in_date"),
        check_out_date: searchParams.get("check_out_date"),
        adults: searchParams.get("adults"),
        children: searchParams.get("children"),
        rooms: searchParams.get("rooms"),
      };

      const fullHotelDetailsParam = {
        hotel_slug: slug,
        languageToShow: languageToShow,
        token: userAuthData != null ? userAuthData.token : "",
        searchedParams: searchedParams,
      };
      getFullHotelDetailsRequest(fullHotelDetailsParam);
    }

    if (searchParams.get("search_type") == "hour") {
      const searchedParams = {
        search_type: searchParams.get("search_type"),
        book_for: searchParams?.get("book_for"),
        city: searchParams?.get("city"),
        check_in_date: searchParams.get("check_in_date"),
        adults: searchParams.get("adults"),
        children: searchParams.get("children"),
        rooms: searchParams.get("rooms"),
      };
      const fullHotelDetailsParam = {
        hotel_slug: slug,
        languageToShow: languageToShow,
        token: userAuthData != null ? userAuthData.token : "",
        searchedParams: searchedParams,
      };

      getFullHotelDetailsRequest(fullHotelDetailsParam);
      placeStateDataSave(searchParams.get("city"));
    }
    window.scroll(0, 0);
  }, [languageToShow]);

  useEffect(() => {
  return () => {
  hotelRouteDetailsStateHandler("BiddingDetails")    
  };
  }, []);
  

  

  
 
  return(
  <>
  {/* for SEO purpose */}
  <Helmet>
        <title>Hotel Details - Discover Your Perfect Stay | Wfrlee</title>
        <meta name="description" content="Find your perfect stay! Explore Wfrlee hotels: amenities, reviews & deals. Book your dream accommodation now." />
      </Helmet>
  <HotelListingDetails hotel_slug={slug} />;
  </>)
};

const mapStateToProps = createStructuredSelector({
  languageToShow: selectlanguageToShow,
  selectHotelData: selectHotelData,
  userAuthData: selectUserLoginData,
  selectHotelBidNowPageRouteData:selectHotelBidNowPageRouteData,
  selectHotelDetailsPageRouteData:selectHotelDetailsPageRouteData
});
const mapDispatchToProps = (dispatch) => ({
  getFullHotelDetailsRequest: (data) => dispatch(getFullHotelDetailsRequest(data)),
  selectLanguage: (data) => dispatch(selectLanguage(data)),
  placeStateDataSave: (data) => dispatch(placeStateDataSave(data)),
  hotelRouteDetailsStateHandler:(data) => dispatch(hotelRouteDetailsStateHandler(data))
  
});

export default connect(mapStateToProps, mapDispatchToProps)(ListingdetailsPage);
