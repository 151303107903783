import React, { useState } from "react";
import { Button, Dropdown, Form, Tabs, Tab } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FiLogIn } from "react-icons/fi";
import { BiUserCircle } from "react-icons/bi";
import { GrFacebookOption } from "react-icons/gr";
import { GrTwitter } from "react-icons/gr";
import { GrLinkedinOption } from "react-icons/gr";
import { GrInstagram } from "react-icons/gr";
import { GrYoutube } from "react-icons/gr";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import validator from "validator";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { selectUserLoginData } from "./../../redux/user/user.selectors";
import { addContactUsRequest, stateClearAfterTask } from "./../../redux/useraccount/useraccount.actions";
import { careerFileUploadRequest } from "./../../redux/common/fileUpload.actions";
import { selectFileUploadData } from "./../../redux/common/fileUpload.selectors";
import { selectAddContactData } from "./../../redux/useraccount/useraccount.selectors";
import { createStructuredSelector } from "reselect";
import SuccessDialog from "../common/dialogs/SuccessDialog";
import {
  MobileNumberValidator,
  NumberValidator,
  EmailValidator,
} from "../../constants/InputValidator";

const ContactusForm = ({
  addContactUsRequest,
  stateClearAfterTask,
  fileData,
  contactusData,
}) => {
  const [t] = useTranslation("common");
  const location = useLocation();

  const [userData, setUserData] = useState({
    firstname: "",
    lastname: "",
    email: "",
    mobileNo: "",
    subject: "",
    message: "",
    // attachments: [],
  });

  const [userDataError, setuserDataError] = useState({
    firstnameErr: "",
    lastnameErr: "",
    emailErr: "",
    mobileNoErr: "",
    subjectErr: "",
    messageErr: "",
  });
  const [countryCode, setCountryCode] = React.useState("+966");
  const [countryCodeErr, setCountryCodeErr] = React.useState("");
  const [showSuccessDialog, setShowSuccessDialog] = useState(false);
  const translatedMessageObj = {
    MobileNumberValidatorMsgObj: {
      pleaseEnterYourMobileNumber: t(
        "validatorMsg.pleaseEnterYourMobileNumber"
      ),
      PleaseEnteronlyNumber: t("validatorMsg.PleaseEnteronlyNumber"),
      Atleast10DigitsRequired: t("validatorMsg.Atleast10DigitsRequired"),
      MobileNumberIsInvalid: t("validatorMsg.MobileNumberIsInvalid"),
    },
    EmailValidatorMsgObj: {
      PleaseEnterYourEmail: t("validatorMsg.PleaseEnterYourEmail"),
      PleaseEnteraValidEmailAddress: t(
        "validatorMsg.PleaseEnteraValidEmailAddress"
      ),
    },
    PleaseEnteronlyNumber: t("validatorMsg.PleaseEnteronlyNumber"),
  };

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const navigate = useNavigate();

  const uploadFile = (data) => {
    const fileUploadData = data[0];

    const postedData = {
      document: fileUploadData,
    };

  };

  /*** Country Code Selection   ***/
  const handleCountryCodeChange = (value, data, event, formattedValue) => {
    if (data.dialCode == "") {
      setCountryCode("");
    } else {
      setCountryCode(formattedValue);
      setCountryCodeErr("");
    }
  };
  const handleChange = (e) => {
    if (e.target.name == "firstname") {
      setuserDataError({
        ...userDataError,
        firstnameErr: "",
      });
    } else if (e.target.name == "lastname") {
      setuserDataError({
        ...userDataError,
        lastnameErr: "",
      });
    } else if (e.target.name == "email") {
      setuserDataError({
        ...userDataError,
        emailErr: "",
      });
    } else if (e.target.name == "mobileNo") {
      const isNumberError = MobileNumberValidator(e.target.value,translatedMessageObj.MobileNumberValidatorMsgObj);
      if (isNumberError) {
        setuserDataError({
          ...userDataError,
          mobileNoErr: isNumberError,
        });
      } else {
        setuserDataError({
          ...userDataError,
          mobileNoErr: "",
        });
      }
    } else if (e.target.name == "subject") {
      setuserDataError({
        ...userDataError,
        subjectErr: "",
      });
    } else {
      setuserDataError({
        ...userDataError,
        messageErr: "",
      });
    }
    setUserData({
      ...userData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const mobileNoError = MobileNumberValidator(userData.mobileNo,translatedMessageObj.MobileNumberValidatorMsgObj);
    if (userData.firstname == "") {
      setuserDataError({
        ...userDataError,
        firstnameErr: t("ContactusPage.form.validationMsg.nameErrMsg"),
      });
      return;
    } else if (userData.lastname == "") {
      setuserDataError({
        ...userDataError,
        lastnameErr: t("ContactusPage.form.validationMsg.lastnameErrMsg"),
      });
      return;
    } else if (!validator.isEmail(userData.email)) {
      setuserDataError({
        ...userDataError,
        emailErr: t("ContactusPage.form.validationMsg.emailErrMsg"),
      });
      return;
    } else if (countryCode == "") {
      
      setCountryCodeErr(t("ContactusPage.form.validationMsg.counrtycodeErrMsg"));
      return;
    } else if (mobileNoError) {
      setuserDataError({
        ...userDataError,
        mobileNoErr: mobileNoError,
      });
      return;
  
    } else if (userData.subject == "") {
      setuserDataError({
        ...userDataError,
        subjectErr: t("ContactusPage.form.validationMsg.subjectErrMsg"),
      });
      return;
    } else if (userData.message == "") {
      setuserDataError({
        ...userDataError,
        messageErr: t("ContactusPage.form.validationMsg.commentErrMsg"),
      });
      return;
    } else {
      
      const data = {
        first_name: userData.firstname,
        last_name: userData.lastname,
        email: userData.email,
        country_code: countryCode,
        contact_no: userData.mobileNo,
        subject: userData.subject,
        message: userData.message,
        // attachments: attachmentsFileArr,
      };
      addContactUsRequest(data);
      setUserData({
        firstname: "",
        lastname: "",
        email: "",
        mobileNo: "",
        subject: "",
        message: "",
        // attachments: [],
      });
     setCountryCode("+966");
    }
  };

  useEffect(() => {
    if (contactusData != null) {
      if (contactusData.success == true) {
        
        setShowSuccessDialog(true);
      } else {
      }
      
    }
  }, [JSON.stringify(contactusData)]);

  const handleCloseDialog = () => {
    setShowSuccessDialog(false);
    // navigate(-1);
    stateClearAfterTask();
  };
  useEffect(() => {
    if (contactusData == null) {
      setShowSuccessDialog(false);
    }
  }, [contactusData]);

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <div className="form50">
          <Form.Group controlId="formContactFirstname">
            <Form.Label>
              {/* Name * */}
              {t("ContactusPage.form.FirstName")} *
            </Form.Label>
            <Form.Control
              type="text"
              placeholder={t("ContactusPage.form.FirstName")}
              onChange={handleChange}
              value={userData.firstname}
              name="firstname"
            />
            <Form.Text className="text-muted errorformmessage">
              {userDataError.firstnameErr}
            </Form.Text>
          </Form.Group>
          <Form.Group className="lastname" controlId="formContactLastname">
            <Form.Label>{t("ContactusPage.form.LastName")} *</Form.Label>
            <Form.Control
              type="text"
              placeholder={t("ContactusPage.form.LastName")}
              name="lastname"
              onChange={handleChange}
              value={userData.lastname}
            />
            <Form.Text className="text-muted errorformmessage">
              {userDataError.lastnameErr}
            </Form.Text>
          </Form.Group>
        </div>
        <Form.Group controlId="formCareerEmail">
          <Form.Label>{t("ContactusPage.form.Email")} *</Form.Label>
          <Form.Control
            type="email"
            placeholder={t("ContactusPage.form.Emailaddress")}
            name="email"
            value={userData.email}
            onChange={handleChange}
          />
          <Form.Text className="text-muted errorformmessage">
            {userDataError.emailErr}
          </Form.Text>
        </Form.Group>
        <div className="form50">
          <Form.Group controlId="formCareerPhone">
            <Form.Label>{t("ContactusPage.form.MobileNumberText")} *</Form.Label>
            <Form.Group
              controlId="formCheckoutPhone"
              className="phoneWithCountryCode"
            >
              <Form.Group controlId="formCheckoutCountryCode">
                <PhoneInput
                  autoFormat={false}
                  // country={"in"}
                  excludeCountries={['cu','ru','kp','ua','ir','sd','ss','sy']}
                  enableSearch={true}
                  value={countryCode}
                  onChange={handleCountryCodeChange}
                  name="countryCode"
                  placeholder={"+91"}
                  className="checkoutcountryCodeInput"
                />
              </Form.Group>
              <Form.Group
                controlId="formCheckoutPoneNumber"
                className="checkoutPhoneNumber"
              >
                <Form.Control
                  type="text"
                  placeholder={t("ContactusPage.form.MobileNumber")}
                  name="mobileNo"
                  value={userData.mobileNo}
                  onChange={handleChange}
                  maxLength={10}
                />
              </Form.Group>
            </Form.Group>
            <Form.Text className="text-muted errorformmessage">
              {userDataError.mobileNoErr} {countryCodeErr}
            </Form.Text>
          </Form.Group>
          <Form.Group controlId="formContactSubject">
            <Form.Label>{t("ContactusPage.form.Subject")} *</Form.Label>
            <Form.Select
              value={userData.subject}
              name="subject"
              onChange={handleChange}
              placeholder={t("ContactusPage.form.Selectsubject")}
            >
              <option disabled  value="">
                {t("ContactusPage.form.Selectsubject")}
              </option>
              <option value="Suggestion">
                {t("ContactusPage.form.selectDropdown.Suggestion")}
              </option>
              <option value="General Inquiry">
                {t("ContactusPage.form.selectDropdown.GeneralInquiry")}
              </option>
              <option value="Special Request">
                {t("ContactusPage.form.selectDropdown.SpecialRequest")}
              </option>
              <option value="Problem with Booking">
                {t("ContactusPage.form.selectDropdown.ProblemwithBooking")}
              </option>
            </Form.Select>
            <Form.Text className="text-muted errorformmessage">
              {userDataError.subjectErr}
            </Form.Text>
          </Form.Group>
        </div>
        <Form.Group controlId="formContactMessage">
          <Form.Label>{t("ContactusPage.form.Message")} *</Form.Label>
          <Form.Control
            as="textarea"
            placeholder={t("ContactusPage.form.Yourmessage")}
            value={userData.message}
            name="message"
            onChange={handleChange}
          />
          <Form.Text className="text-muted errorformmessage">
            {userDataError.messageErr}
          </Form.Text>
        </Form.Group>

        

        <Button variant="" type="submit" className="formsubmit">
          {t("ContactusPage.form.Send")}
        </Button>
      </Form>
      <SuccessDialog
        setShowSuccessDialog={setShowSuccessDialog}
        isOpen={showSuccessDialog}
        caption={contactusData != null ? contactusData?.message : ""}
        description={<></>}
        cancelButtonLabel={t("ContactusPage.form.Done")}
        clearState={stateClearAfterTask}
        cancelButtonHandler={handleCloseDialog}
      />
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  contactusData: selectAddContactData,
  userAuthData: selectUserLoginData,
  fileData: selectFileUploadData,
});

const mapDispatchToProps = (dispatch) => ({
  addContactUsRequest: (data) => dispatch(addContactUsRequest(data)),
  stateClearAfterTask: () => dispatch(stateClearAfterTask()),
  careerFileUploadRequest: (data) => dispatch(careerFileUploadRequest(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ContactusForm);
