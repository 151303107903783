import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import currencyRates from "./currencyRates.json";
/*
This custom hook has been created to fetch converted currency and save them in redux
*/

export const CurrencyConverterHook = () => {
  //const dispatch = useDispatch();
  // const selectCurrency = useSelector((state) => state.currency.currency);

  const currencyConverted = async (converted) => {
    try {
      const options = {
        method: "GET",
        url: process.env.REACT_APP_RAPIDAPI_URL_FOR_CURRENCY,
        headers: {
          "X-RapidAPI-Key": process.env.REACT_APP_RAPIDAPI_KEY,
          "X-RapidAPI-Host": process.env.REACT_APP_RAPIDAPI_HOST_FOR_CURRENCY,
        },
        params: { base: converted.prev, symbols: converted.current },
      };


      //When currency key is activated uncomment this --- start
      let res = await axios(options);
      return res;
      //When currency key is activated uncomment this --- end
      //  return {
      //   status:200,
      //   data:{
      //     rates:{
      //       'AED':1,
      //       'AFN':1,
      //       'AUD':1,
      //       'CAD':1,
      //       'EUR':1,
      //       'INR':1,
      //       'SAR':1,
      //       'USD':1
      //     }
      //     // rates: currencyRates
      //   }
      //  }
    } catch (error) {}
    // const payload = value;
    // dispatch({ type: 'DISPUTE_VIEW_MODAL_ID', payload });
  };

  return { currencyConverted };
};
