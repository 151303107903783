
/****** Home Page Redux Types  *******/
const HomeActionTypes = {
GET_OFFER_PROMOTIONS_PARTNERS_PREFERENCE_DATA_REQUEST: "GET_OFFER_PROMOTIONS_PARTNERS_PREFERENCE_DATA_REQUEST",
GET_OFFER_PROMOTIONS_PARTNERS_PREFERENCE_DATA_SUCCESS: "GET_OFFER_PROMOTIONS_PARTNERS_PREFERENCE_DATA_SUCCESS",
GET_OFFER_PROMOTIONS_PARTNERS_PREFERENCE_DATA_FAILED: "GET_OFFER_PROMOTIONS_PARTNERS_PREFERENCE_DATA_FAILED",

/************* Home Page Data Preference  ***********************/
GET_PREFERENCE_DATA_REQUEST_LIST: "GET_PREFERENCE_DATA_REQUEST_LIST",
/************* Home Page Data Preference  ***********************/

/*********** HOME PAGE REVIEW HOME PAGE ************/
GET_HOME_PAGE_RANDOM_REVIEW_REQUEST:"GET_HOME_PAGE_RANDOM_REVIEW_REQUEST",
GET_HOME_PAGE_RANDOM_REVIEW_SUCCESS:"GET_HOME_PAGE_RANDOM_REVIEW_SUCCESS",
GET_HOME_PAGE_RANDOM_REVIEW_FAILED:"GET_HOME_PAGE_RANDOM_REVIEW_FAILED"
/*********** HOME PAGE REVIEW HOME PAGE ************/


}

export default HomeActionTypes;

/****** Home Page Redux Types  *******/


