import React from "react";
import { Modal } from "react-bootstrap";

const BookingConditionModal = (
  { show, handleClose, bookingCondition },
  props
) => {
  return (
    <Modal
      dialogClassName="modal-lg gen-modal infoModal loginModal"
      show={show}
      onHide={handleClose}
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <div className="pop-info-content">
          <h3>{bookingCondition?.cancellation_popup_title}</h3>
          {bookingCondition?.cancellation_popup_body.length > 0
            ? bookingCondition?.cancellation_popup_body.map((data, index) => {
                return <p key={index}>{data}</p>;
              })
            : ""}
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default BookingConditionModal;
