import { makeStyles } from "@mui/styles";

export const useSuccessDialogStyles = makeStyles(
  {
    dialogPopup: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      "& .MuiDialog-paper": {
        borderRadius: "10px",
        overflowX: "hidden",
      },
      "& .css-yiavyu-MuiBackdrop-root-MuiDialog-backdrop": {
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        zIndex: "-1",
      },
      "& .MuiDialogActions-root": {
          display: "flex",
          justifyContent: "center"
      }
    },
    dialogContent: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      padding: "20px 40px",
      borderTop: "1px solid lightgrey",
    },
    dialogText: {
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      alignItems: "center",
      marginTop: "20px",
    },
    buttonBox: {
      display: "flex",
      justifyContent: "center",
      flexDirection: "row",
      padding: "20px 40px !important",
    },

    yesBtn: {
      background: "linear-gradient(180deg, #75a0c3 0%, #5287b3 100%)",
      color: "#fff !important",
      borderRadius: "8px",
      fontSize: "16px",
      fontWeight: "500",
      width: "100%",
      outline: "none",
      boxShadow: "none",
      textTransform: "capitalize !important",
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      padding: "12px",
      width: "250px",
      "&:hover": {
        background: "#42588b !important",
        color: "#fff",
      },
    },
    cancelBtn: {
      background: "linear-gradient(180deg, #75a0c3 0%, #5287b3 100%)",
      borderRadius: "8px",
      fontSize: "16px",
      fontWeight: "500",
      color: "#fff !important",
      width: "100%",
      outline: "none",
      boxShadow: "none",
      textTransform: "capitalize !important",
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      padding: "12px",
      width: "130px",
      "&:hover": {
        background: "#42588b !important",
        color: "#fff !important",
      },
    },
    heading: {
      fontWeight: "500",
      fontSize: "16px",
      lineHeight: "140%",
      textAlign: "center",
      padding: "20px 0",
    },

    notifyText: {
      fontWeight: "400",
      fontSize: "12px",
    },

    "& .css-hlj6pa-MuiDialogActions-root>:not(:first-of-type)": {
      transition: "none",
    },
    checkIcon: {
        "& svg": {
            color: "#5fb85f",
            fontSize: "70px",
        },
    },
  },
  { index: 1 }
);
