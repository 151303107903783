import React, { useEffect } from "react";
import { Button, Dropdown, Form, Tabs, Tab } from "react-bootstrap";
import {
  Link,
  useLocation,
  useNavigate,
  createSearchParams,
} from "react-router-dom";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';

import CloseIcon from '@mui/icons-material/Close';
import { FiLogIn } from "react-icons/fi";
import { BiUserCircle } from "react-icons/bi";
import { GrFacebookOption } from "react-icons/gr";
import { GrTwitter } from "react-icons/gr";
import { GrLinkedinOption } from "react-icons/gr";
import { GrInstagram } from "react-icons/gr";
import { GrYoutube } from "react-icons/gr";
import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";
import { selectLanguage } from "./../../redux/language/language.actions";
import { selectlanguageToShow } from "./../../redux/language/language.selectors";
import { bidingGetDataStart } from "./../../redux/useraccount/useraccount.actions";
import { selectUserAccountBidingData } from "./../../redux/useraccount/useraccount.selectors";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import moment from "moment";
import HandleOutsideClick from "react-outside-click-event";

import BookingConfirmationDetailsModal from "../../components/checkout/BookingConfirmationDetailsModal";
import Pagination from "@mui/material/Pagination";
import {
  selectUserLoginData,
  selectSocialLoginUser,
} from "../../redux/user/user.selectors";
import { hotelResendConfirmationRequest, hotelBiddingCancelationRequest, hotelBookingListRequest } from "./../../redux/hotels/hotel.actions";
import { selectResendConfirmationLoading, selectHotelBookingCancellationData, cancelSuccessMessage, selectHotelBookingDetails } from "./../../redux/hotels/hotel.selectors";
import ShareModal from "../../utils/ShareModal";
import { showLoader, hideLoader } from "../../Loader/loader";
import { useTranslation } from "react-i18next";
import MyAccountLeftMenu from "../../components/MyAccount/MyAccountLeftMenu";
import { Helmet } from "react-helmet";

import Draggable from 'react-draggable';
import Paper from '@mui/material/Paper';
import { data } from "jquery";




const MybiddingsPage = ({
  bidingGetDataStart,
  selectUserAccountBidingData,
  languageToShow,
  userAuthData,
  hotelResendConfirmationRequest,
  resendConfirmationLoading,
  hotelBiddingCancelationRequest,
  cancelSuccessMessage,
  hotelBookingListRequest,
  bookingDetails
}) => {
  const [t] = useTranslation("common");
  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(3);
  const location = useLocation();
  const navigate = useNavigate();
  const [openSharePopup, setOpenSharePopup] = React.useState(false);
  const [isCopied, setIsCopied] = React.useState(false);
  const [shareCode, setShareCode] = React.useState();
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [pendingDialog, setPendingDialog] = React.useState(false);
  const [bookingId, setBookingId] = React.useState("");
  const [biddingPopupMessage, setBiddingPopupMessage] = React.useState("");
  const [biddingPopupTitle, setBiddingPopupTitle] = React.useState("");
  const [biddingPopUpStatus, setBiddingPopUpStatus] = React.useState(0);
  const [bookingConformOpen, setBookingConformOpen] = React.useState(false);
  const [confirmBookingPopup, setConfirmBookingPopup] = React.useState(false);
  const perPageLimit = 3;
  useEffect(() => {
    const data = {
      languageToShow: languageToShow,
      token: userAuthData != null ? userAuthData.token : "",
      skip: page,
      limit: perPageLimit,
    };
    bidingGetDataStart(data);
    setPage(1);
    setRowsPerPage(perPageLimit);
  }, [languageToShow, cancelSuccessMessage]);



  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    const data = {
      languageToShow: languageToShow,
      token: userAuthData != null ? userAuthData.token : "",
      skip: newPage,
      limit: perPageLimit,
    };
    bidingGetDataStart(data);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const gotoHotelDetails = (slug, cityname) => {
    var checkinDate = new Date();
    checkinDate.setDate(checkinDate.getDate() + 1);
    var checkoutDate = new Date();
    checkoutDate.setDate(checkoutDate.getDate() + 2);

    if (location.pathname == `/hotel-details/${slug}`) {
      navigate({
        pathname: location.pathname,
        search: createSearchParams({
          search_type: "bid",
          city: cityname,
          check_in_date: moment(new Date(checkinDate)).format("DD/MM/YYYY"),
          check_out_date: moment(new Date(checkoutDate)).format("DD/MM/YYYY"),

          adults: "1",
          children: "1",
          rooms: "1",
        }).toString(),
      });
    } else {
      navigate(
        {
          pathname: `/hotel-details/${slug}`,
          search: createSearchParams({
            search_type: "bid",
            city: cityname,
            check_in_date: moment(new Date(checkinDate)).format("DD/MM/YYYY"),
            check_out_date: moment(new Date(checkoutDate)).format("DD/MM/YYYY"),

            adults: "1",
            children: "1",
            rooms: "1",
          }).toString(),
        },
        { replace: false }
      );
    }
  };

  const handleResendConfirmation = (bookingNo) => {
    const postData = {
      booking_number: bookingNo,
    };
    const data = {
      languageToShow: languageToShow,
      token: userAuthData != null ? userAuthData.token : "",
      postData: postData,
    };
    hotelResendConfirmationRequest(data);
  };

  const handleSharePopup = (shareData) => {
    if (shareData) {
      setShareCode(shareData);
      setOpenSharePopup(!openSharePopup);
    } else {
    }
  };

  const onCopy = React.useCallback((value) => {
    setIsCopied(value);
    setOpenSharePopup(false);
  }, []);

  React.useEffect(() => {
    // showLoader();
    if (resendConfirmationLoading) {
      showLoader();
    } else {
      hideLoader();
    }
  }, [resendConfirmationLoading]);
  const handleClose = () => {
    setDialogOpen(false);
  }
  const handleCancel = () => {
    const data = {
      "biding_id": bookingId,
      "status": "canceled",
    }
    hotelBiddingCancelationRequest(data);
    setDialogOpen(false);
  }

  const handleShowBiddingDetailsPopup = (data) => {
    if (data?.biding_status === 6) {
      setBiddingPopUpStatus(data?.biding_status);
      setBiddingPopupTitle(t("MyAccount.MyBiddingsPage.Pending"));
      setBiddingPopupMessage(t("MyAccount.MyBiddingsPage.YourbidrequestisstillwaitingfortheApproval"))
      setPendingDialog(true);
    } else if (data?.biding_status === 1) {
      setBiddingPopUpStatus(data?.biding_status);
      hotelBookingListRequest({ ticket: data?.booking_number });
      setConfirmBookingPopup(true)
    } else if (data?.biding_status === 2) {
      setBiddingPopUpStatus(data?.biding_status);
      setBiddingPopupTitle(t("MyAccount.MyBiddingsPage.Rejected"));
      setBiddingPopupMessage(t("MyAccount.MyBiddingsPage.Yourbidrequesthasbeenrejected"));
      setPendingDialog(true);
    } else if (data?.biding_status === 3 || data?.biding_status === 4 || data?.biding_status === 5) {
      setBiddingPopUpStatus(data?.biding_status);
      setBiddingPopupTitle(t("MyAccount.MyBiddingsPage.Cancel"));
      setBiddingPopupMessage(t("MyAccount.MyBiddingsPage.Yourbidrequesthasbeencenceled"));
      setPendingDialog(true);
    } else {

    }

  };

  useEffect(() => {
    if (confirmBookingPopup == true && bookingDetails != null) {
      setBookingConformOpen(true);
    }
  }, [confirmBookingPopup, bookingDetails])


  return (
    <>

      {/* for SEO purpose */}
      <Helmet>
        <title>My Bidding - Manage Your Auctions | Wfrlee</title>
        <meta name="description" content="Access and manage your active and past bids on Wfrlee. Explore bidding histories, update your offers, and stay ahead in the auction game all from one convenient location." />
      </Helmet>

      {/* Header Section */}

      {/* //////////Myprofile page Section/////////// */}
      <div className="myaccountpage">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="accounttotalsection">
                <div className="leftmenu">
                  <div className="account-titlearea">
                    <h2>{t("MyAccount.heading")}</h2>
                    <p>{t("MyAccount.subHeading")}</p>
                  </div>
                  <MyAccountLeftMenu />
                </div>
                <div className="rightsection">
                  <h1>{t("MyAccount.MyBiddingsPage.heading")}</h1>
                  <div className="accountbooking">
                    {selectUserAccountBidingData != null ? (
                      <>
                        {(selectUserAccountBidingData.data || []).map(
                          (data, index) => {
                            return (
                              <div className="bookingbox" key={index}>
                                <div className="bookingboxlft"
                                  onClick={() => {

                                    handleShowBiddingDetailsPopup(data);
                                  }}
                                >
                                  <h3
                                    onClick={() => {
                                      gotoHotelDetails(
                                        data.hotel_details.slug,
                                        data.hotel_details.address.city_village
                                      );
                                    }}
                                  >
                                    {" "}
                                    {data?.hotel_details?.name}
                                  </h3>
                                  <p>
                                    {" "}
                                    {data?.hotel_details?.address.district}{" "}
                                    <br />
                                    <span>
                                      {
                                        data?.hotel_details?.address
                                          .address_line1
                                      }
                                    </span>
                                    {data?.hotel_details?.address
                                      .address_line2 && (
                                        <span>
                                          ,{" "}
                                          {
                                            data?.hotel_details?.address
                                              .address_line2
                                          }
                                          ,{" "}
                                        </span>
                                      )}{" "}
                                    {data?.hotel_details?.address
                                      .city_village && (
                                        <span>
                                          {
                                            data?.hotel_details?.address
                                              .city_village
                                          }{" "}
                                          -
                                        </span>
                                      )}
                                    <span>
                                      {" "}
                                      {data?.hotel_details?.address.pincode}
                                    </span>
                                  </p>
                                  <p>{data?.hotel_details?.address.country}</p>

                                </div>
                                <div className="bidarea"
                                  onClick={() => {

                                    handleShowBiddingDetailsPopup(data);
                                  }}
                                >
                                  <div className="bookingboxmid">
                                    <p>
                                      {" "}
                                      <label>{t("MyAccount.MyBiddingsPage.DateofBidding")}</label>
                                      <br />{" "}
                                      <span>
                                        {/* 15 Dec, 2022{" "} */}
                                        {moment(new Date(data?.createdAt)).format(
                                          "DD MMM, YYYY"
                                        )}
                                      </span>

                                    </p>
                                  </div>
                                  <div className="bookingboxmid bidprice">
                                    <p>
                                      {" "}
                                      <label>{t("MyAccount.MyBiddingsPage.BidPrice")}</label>
                                      <br />{" "}
                                      <span>AED {data?.bid_offered}</span>
                                    </p>
                                  </div>
                                  <div className="bookingboxmid bidstatus">
                                    <p>
                                      {" "}
                                      <label>{t("MyAccount.MyBiddingsPage.Status")}</label>{" "}
                                      {data?.biding_status === 1 && (
                                        <span className="acceptcolor">
                                          {t("MyAccount.MyBiddingsPage.Accepted")}
                                        </span>
                                      )}
                                      {data?.biding_status === 6 && (
                                        <span className="pendingcolor">
                                          {t("MyAccount.MyBiddingsPage.Pending")}
                                        </span>
                                      )}
                                      {(data?.biding_status === 2) && (
                                        <span className="rejectcolor">
                                          {t("MyAccount.MyBiddingsPage.Rejected")}
                                        </span>
                                      )}
                                      {(data?.biding_status === 3 || data?.biding_status === 4 || data?.biding_status === 5) && (
                                        <span className="rejectcolor">
                                          {t("MyAccount.MyBiddingsPage.Cancel")}
                                        </span>
                                      )}
                                    </p>
                                  </div>
                                </div>
                                <div className="bookingboxrgt">
                                  {data?.biding_status == 1 ?
                                    <>
                                      <Button
                                        onClick={() =>
                                          handleResendConfirmation(
                                            data.booking_number
                                          )
                                        }
                                        className={"gen-btn"}
                                      >
                                        {t("MyAccount.MyBiddingsPage.ResendConfirmation")}
                                      </Button>
                                      <Button
                                        onClick={() => {
                                          handleSharePopup(data.share_link);
                                        }}
                                        className={"gen-btn"}
                                      >
                                        {" "}
                                        {t("MyAccount.MyBiddingsPage.ShareConfirmation")}
                                      </Button>
                                    </> : null
                                  }
                                  {
                                    data?.biding_status === 1 || data?.biding_status === 6 ?
                                      (<>
                                        <Button
                                          onClick={() => {

                                            setDialogOpen(true);
                                            setBookingId(data.id)
                                          }}
                                          className={"gen-btn"}
                                        >
                                          {" "}
                                          {t(
                                            "MyAccount.MyBiddingsPage.CancelBidding"
                                          )}
                                        </Button>
                                      </>
                                      )
                                      :
                                      ""
                                  }



                                </div>
                              </div>
                            );
                          }
                        )}
                      </>
                    ) : (
                      // If no data
                      <div className="bookingbox">
                        <div className="bookingboxlft">
                          <h3> {t("MyAccount.MyBiddingsPage.NoDataFound")}</h3>
                        </div>
                      </div>
                    )}
                    {/* static data */}

                    <Pagination
                      boundaryCount={1}
                      count={
                        selectUserAccountBidingData != null
                          ? Math.ceil(
                            selectUserAccountBidingData?.total_count /
                            perPageLimit
                          )
                          : 0
                      }
                      defaultPage={1}
                      onChange={handleChangePage}
                      page={page}
                      showFirstButton={true}
                      showLastButton={true}
                    />


                    <ShareModal
                      setOpenSharePopup={setOpenSharePopup}
                      openSharePopup={openSharePopup}
                      shareCode={shareCode}
                      setShareCode={setShareCode}
                      onCopy={onCopy}
                      isCopied={isCopied}
                      setIsCopied={setIsCopied}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Dialog
        open={dialogOpen}
        onClose={handleClose}
        fullWidth={fullScreen}
        maxWidth="sm"
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "500px", // Set your width here
            },
          },
        }}
      >
        <DialogTitle >
          Cancel Bidding
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure want to cancel ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button className={"gen-btn-conformation-modal"} style={{ width: "142px" }} onClick={handleClose}>
            No
          </Button>
          <Button className={"gen-btn-conformation-modal"} style={{ width: "142px" }} onClick={handleCancel}>
            Yes
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog className="custompops"
        open={pendingDialog}
        onClose={handleClose}
        fullWidth={fullScreen}
        maxWidth="sm"
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "700px", // Set your width here
              padding: "30px",
            },
          },
        }}
      >
        <DialogTitle style={biddingPopUpStatus == 6 ? { color: "#FF5733" } : biddingPopUpStatus == 2 ? { color: "#C70039" } : { color: "#C70039" }} >
          {biddingPopupTitle}
          <Box display="flex" alignItems="center" className="closepop">
            <Box flexGrow={1} ></Box>
            <Box>
              <IconButton onClick={() => { setPendingDialog(false); }}>
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {biddingPopupMessage}

          </DialogContentText>
        </DialogContent>
      </Dialog>
      <BookingConfirmationDetailsModal
        setShowDialog={setBookingConformOpen}
        isOpen={bookingConformOpen}
        bookingData={bookingDetails != null ? bookingDetails.data : null}
        cancelButtonLabel={t("checkoutPage.Close")}
        cancelDialogHandler={() => {
          setBookingConformOpen(false);
          navigate("/mybooking");

        }}
      />
      {/* </HandleOutsideClick> */}
      {/* //////////Myprofile page Section/////////// */}
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  languageToShow: selectlanguageToShow,
  selectUserAccountBidingData: selectUserAccountBidingData,
  userAuthData: selectUserLoginData,
  userSocialAuthData: selectSocialLoginUser,
  resendConfirmationLoading: selectResendConfirmationLoading,
  cancelSuccessMessage: selectHotelBookingCancellationData,
  bookingDetails: selectHotelBookingDetails
});
const mapDispatchToProps = (dispatch) => ({
  bidingGetDataStart: (data) => dispatch(bidingGetDataStart(data)),
  selectLanguage: (data) => dispatch(selectLanguage(data)),
  hotelResendConfirmationRequest: (data) =>
    dispatch(hotelResendConfirmationRequest(data)),
  hotelBiddingCancelationRequest: (data) => dispatch(hotelBiddingCancelationRequest(data)),
  hotelBookingListRequest: (data) => dispatch(hotelBookingListRequest(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(MybiddingsPage);
