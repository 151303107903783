import React, { useState, useEffect } from "react";
import { Button, Form } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import {
  ForgotPasswordStart,
  stateClearAfterTask,
} from "./../../redux/user/user.actions";
import {
  selectCurrentUser,
  selectSignInError,
  selectorForgotPasswordLoading,
} from "../../redux/user/user.selectors";
import { createStructuredSelector } from "reselect";
import Spinner from "react-bootstrap/Spinner";
import { connect } from "react-redux";
const ForgotPassword = ({
  currentUser,
  ForgotPasswordStart,
  signinerror,
  stateClear,
  forgotPasswordLoading,
}) => {
  const navigate = useNavigate();
  const [eye, seteye] = useState(false);
  const [userData, setUserData] = useState({
    email: "",
    password: "",
  });

  const [userDataError, setuserDataError] = useState({
    emailErr: "",
    passwordErr: "",
  });
  useEffect(() => {
    if (signinerror != null) {
      stateClear();
    }
  }, [signinerror]);

  useEffect(() => {
    if (currentUser != null) {
      navigate("/");
    }
  }, [currentUser]);

  const handleChange = (e) => {
    if (e.target.name == "email") {
      setuserDataError({
        ...userDataError,
        emailErr: "",
      });
    }
    setUserData({
      ...userData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (userData.email == "") {
      setuserDataError({
        ...userDataError,
        emailErr: "Please Enter Your Email",
      });
      return;
    } else {
      var data = {
        email: userData.email,
      };
    }
    ForgotPasswordStart(data);
  };

  return (
    <>
      <Form onSubmit={handleSubmit} className="forgetpassw">
        <Form.Group controlId="formBasicEmail">
          <Form.Label>E-mail *</Form.Label>
          <Form.Control
            type="text"
            placeholder="Email address"
            onChange={handleChange}
            name="email"
            value={userData.email}
          />
          <Form.Text className="text-muted errorformmessage">
            {userDataError.emailErr}
          </Form.Text>
        </Form.Group>

        <Button
          variant="primary"
          type="submit"
          className={
            userDataError.emailErr == "" ? "formsubmit mt-4" : "formsubmit"
          }
        >
          {forgotPasswordLoading ? (
            <Spinner animation="border" />
          ) : (
            "Forgot Password"
          )}
        </Button>
      </Form>
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
  signinerror: selectSignInError,
  forgotPasswordLoading: selectorForgotPasswordLoading,
});
const mapDispatchToProps = (dispatch) => ({
  ForgotPasswordStart: (data) => dispatch(ForgotPasswordStart(data)),
  stateClear: () => dispatch(stateClearAfterTask()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
