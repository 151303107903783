import React, { useEffect } from "react";
import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";
import { cmsGetDataStart } from "./../../redux/cms/cms.actions";
import { selectLanguage } from "./../../redux/language/language.actions";
import { selectlanguageToShow } from "./../../redux/language/language.selectors";
import { selectcmsData } from "./../../redux/cms/cms.selectors";
import { useLocation } from "react-router-dom";
import CMSLeftMenu from "../../components/cms/CMSLeftMenu";
import SpecialSection from "../../components/special-section/SpecialSection";
import { Helmet } from 'react-helmet';


const TermsAndConditionPage = ({
  cmsGetDataStart,
  selectLanguage,
  languageToShow,
  selectcmsData,
}) => {
  const location = useLocation();
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    if (location.pathname == "/terms&conditions") {
      var data = {
        language: languageToShow,
        slug: "terms-and-conditions",
      };
    } else if (location.pathname == "/privacy-policy") {
      var data = {
        language: languageToShow,
        slug: "privacy-policy",
      };
    }
    else if (location.pathname == "/rating-and-review") {
      var data = {
        language: languageToShow,
        slug: "random-rating-review-list",
      };
    }
    else if (location.pathname == "/referral") {
      var data = {
        language: languageToShow,
        slug: "referral",
      };
    } else {
      var data = {
        language: languageToShow,
        slug: "safety-and-security",
      };
    }

    cmsGetDataStart(data);
  }, [languageToShow, location]);

  return (
    <>
      {/* //////////Common page Section/////////// */}
      { /* For SEO */}
      {location.pathname == "/terms&conditions" &&
        <Helmet>
          <title>{selectcmsData != null ? selectcmsData.meta_title : null}</title>
          <meta name="description" content={selectcmsData != null ? selectcmsData.meta_description : null} />
        </Helmet>}

      {location.pathname == "/privacy-policy" &&
        <Helmet>
          <title>{selectcmsData != null ? selectcmsData.meta_title : null}</title>
          <meta name="description" content={selectcmsData != null ? selectcmsData.meta_description : null} />
        </Helmet>}

      {location.pathname == "/rating-and-review" &&
        <Helmet>
          <title>{selectcmsData != null ? selectcmsData.meta_title : null}</title>
          <meta name="description" content={selectcmsData != null ? selectcmsData.meta_description : null} />
        </Helmet>}

      {location.pathname == "/safety-security" &&
        <Helmet>
          <title>{selectcmsData != null ? selectcmsData.meta_title : null}</title>
          <meta name="description" content={selectcmsData != null ? selectcmsData.meta_description : null} />
        </Helmet>}

      <div className="commonpage">
        <div className="container">
          <div className="row">
            <div className="col-md-3">
              <CMSLeftMenu location={location} />
            </div>
            <div className="col-md-9">
              <h1>{selectcmsData != null ? selectcmsData.page_title : null}</h1>
              <div
                contentEditable="false"
                dangerouslySetInnerHTML={{
                  __html: `${selectcmsData != null ? selectcmsData.common_content : ""
                    }`,
                }}
              ></div>


            </div>
          </div>
        </div>
      </div>

      {/* //////////Common page Section/////////// */}

      {/* //////////Get Special Section/////////// */}
      <SpecialSection />
      {/* //////////Footer Section/////////// */}
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  languageToShow: selectlanguageToShow,
  selectcmsData: selectcmsData,
});
const mapDispatchToProps = (dispatch) => ({
  cmsGetDataStart: (data) => dispatch(cmsGetDataStart(data)),
  selectLanguage: (data) => dispatch(selectLanguage(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TermsAndConditionPage);
