import HomeActionTypes from "./home.types";
const INITIAL_STATE = {
loading: false,
offer_promotions_partners_preferenceData: null,
perference:null,
randomreview:null
};




const homeReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case HomeActionTypes.GET_OFFER_PROMOTIONS_PARTNERS_PREFERENCE_DATA_REQUEST:
        return {
          ...state,
          loading: true,
        };
      case HomeActionTypes.GET_OFFER_PROMOTIONS_PARTNERS_PREFERENCE_DATA_SUCCESS:
        return {
          ...state,
          offer_promotions_partners_preferenceData: action.payload,
          loading:false
        };
      case HomeActionTypes.GET_OFFER_PROMOTIONS_PARTNERS_PREFERENCE_DATA_FAILED:
        return {
          ...state,
          error: action.payload,
        };
      case HomeActionTypes.GET_PREFERENCE_DATA_REQUEST_LIST: 
        return {
        ...state,
        perference: action.payload,
        }
      case HomeActionTypes.GET_HOME_PAGE_RANDOM_REVIEW_REQUEST:  
        return {
        ...state,
        loading: true,
       };
      case HomeActionTypes.GET_HOME_PAGE_RANDOM_REVIEW_SUCCESS:
       return {
       ...state,
       randomreview: action.payload,
       loading:false
      }; 
      case HomeActionTypes.GET_HOME_PAGE_RANDOM_REVIEW_FAILED:
      return {
      ...state,
      error: action.payload,
      loading:false
      };   
    default:
    return state;  
    }    
}
export default homeReducer;