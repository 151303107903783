const FileUploadActionTypes = {
  CAREER_FILE_UPLOAD_REQUEST: "CAREER_FILE_UPLOAD_REQUEST",
  CAREER_FILE_UPLOAD_SUCCESS: "CAREER_FILE_UPLOAD_SUCCESS",
  CAREER_FILE_UPLOAD_FAILED: "CAREER_FILE_UPLOAD_FAILED",

  FETCH_FILES_REQUEST: "FETCH_FILES_REQUEST",
  FETCH_FILES_SUCCEED: "FETCH_FILES_SUCCEED",
  FETCH_FILES_FAILED: "FETCH_FILES_FAILED",
  RESET_FILE_STORE: "RESET_FILE_STORE",
};

export default FileUploadActionTypes;
