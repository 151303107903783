import { takeLatest, put, all, call } from "redux-saga/effects";
import axios from "axios";
import HotelActionTypes from "./hotel.types";

import {
  errorToast,
  successToast,
} from "../../utils/toastHelper";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import {
  getFullHotelDetailsSuccess,
  getFullHotelDetailsFailure,
  getFullHotelListSuccess,
  getFullHotelListFailure,
  getHotelAnimitySuccess,
  getHotelAnimityError,
  addFavouriteHotelSuccess,
  addFavouriteHotelFailure,
  removeFavouriteHotelSuccess,
  removeFavouriteHotelFailure,
  getAllFavouriteHotelListSuccess,
  getAllFavouriteHotelListFailure,
  getBidCheckoutDetailsSuccess,
  getBidCheckoutDetailsFailure,
  getHourlyCheckoutDetailsSuccess,
  getHourlyCheckoutDetailsFailure,
  hotelBookingSuccess,
  hotelBookingFailure,
  hotelBookingHistorySuccess,
  hotelBookingHistoryFailure,
  applyWalletSuccess,
  applyWalletFailure,
  applyPromoCodeSuccess,
  applyPromoCodeFailure,
  bidYourAmountSuccess,
  bidYourAmountFailure,
  applyHotelOffersSuccess,
  applyHotelOffersFailure,
  getTotalHotelListCount,
  hotelWheatherApiSuccess,
  hotelWheatherApiFailure,
  hotelResendConfirmationSuccess,
  hotelResendConfirmationFailure,
  hotelPerferenceListHandleSuccess,
  hotelPerferenceListDataListFailure,
  hotelListUpdateAfterAddandRemove,
  hotelBookingListAddReviewSuccess,
  hotelBookingListSuccess,
  hotelBiddingCancelationSuccess,
  hotelBiddingCancelationFailure,
  hotelStarFilterApiCallSuccess,
  hotelStarFilterApiCallFailure

} from "./hotel.actions";
import store from "./../store";
var MySwal = withReactContent(Swal);
/************ Full Hotel List   *************** */
var backendUrlEndPoint = process.env.REACT_APP_BACKEND_API_ENDPOINT;
var weatherAPIEndPoint =
  process.env.NODE_ENV == "production"
    ? process.env.REACT_APP_WEATHER_API_ENDPOINT_PROD
    : process.env.REACT_APP_WEATHER_API_ENDPOINT_DEV;

export function* getFullHotelListDatasAsync({ payload: data }) {
  //&sort_by=${data.sort_by}&filters=${data.filters} -- when API complete this section will add
  try {
    const recomended = data.sort_by != "" ? "&sort_by=" + data.sort_by : "";
    const filter = data.filter ? recomended + "&filters=" + data.filters : "";
    const extraurl =
      data.search_type == "bid"
        ? "&check_out_date=" + data.check_out_date + ""
        : " &book_for=" + data.book_for + "";
    const Preference = store.getState().home.perference != null ? typeof store.getState().home.perference == "string" ? `&preference=${encodeURIComponent(JSON.stringify([`${store.getState().home.perference}`]))}`: store.getState().home.perference.length > 0 ? `&preference=${encodeURIComponent(JSON.stringify(store.getState().home.perference))}`: "" : "";  
    const coupons = store.getState().hotel.offerId != null ? `&coupon=${store.getState().hotel.offerId}` : "";
    const pagination = data.page == undefined ? "&skip=1&limit=10" :  "&skip="+data.page+"&limit=10";
    const url = 
      "" +
      backendUrlEndPoint +
      "/hotel/search?search_type=" +
      data.search_type +
      "&city=" +
      data.city +
      "&check_in_date=" +
      data.check_in_date +
      extraurl +
      "&adults=" +
      data.adults +
      "&children=" +
      data.children +
      "&rooms=" +
      data.rooms +
      filter + 
      Preference +
      coupons + 
      pagination ;
    // }&city=${data.city}&check_in_date=${data.check_in_date}${
    //   data.search_type == `bid`
    //     ? `&check_out_date=${data.check_out_date}`
    //     : `&book_for=${data.hours}`
    // }&adults=${data.adults}&children=${data.children}&rooms=${
    //   data.rooms
    // }&sort_by=${data.sort_by}&filters=${data.filters}`
    const hotelDetailsResponse = yield axios.get(url, {
      headers: {
        mobile: "true",
        "Accept-Language": `${data.language}`,
        Authorization:
          store.getState().user.currentUser != null
            ? "Bearer " + store.getState().user.currentUser.token + ""
            : "",
      },
    });
    
  //   if (
  //     hotelDetailsResponse.data.success ||
  //     hotelDetailsResponse.data.message == "No hotel found."
  //   ) {
  //     yield put(getFullHotelListSuccess(hotelDetailsResponse.data.data));
  //     yield put(getTotalHotelListCount(hotelDetailsResponse.data.total_count));
  //   } else {
  //     yield put(getFullHotelListFailure(hotelDetailsResponse.data));
  //   }
  // } catch (error) {
  //   console.log("Network Error");
  // }
  if (
    hotelDetailsResponse.data.success ||
    hotelDetailsResponse.data.total_count == "Hotel found."
  ) {
    const dtttt = store?.getState()?.hotel?.hotelListData != null ?  store?.getState()?.hotel?.hotelListData.hotels : "";
    if( dtttt == "" ) {
    const merger = [...hotelDetailsResponse.data.data.hotels];
    const all_data = {hotels:merger,avg_lat_long:hotelDetailsResponse.data.data.avg_lat_long}
    yield put(getFullHotelListSuccess(all_data)); 
  } else {
    var a = dtttt;
    var b = hotelDetailsResponse.data.data.hotels;
  
  //  var a = [{'id':'123', 'age':22}, {'id':'456', 'age':12}, {'id':'672', 'age':13}];
  //  var b = [{'id':'123', 'age':62}, {'id':'789', 'age':32}];

function remove_duplicates(a, b) {
  for (var i = 0, len = a.length; i < len; i++) { 
      for (var j = 0, len2 = b.length; j < len2; j++) { 
          if (a[i]._id === b[j]._id) {
              b.splice(j, 1);
              len2=b.length;
          }
      }
  }
return [...a,...b]    

}

const current_HOTELS = remove_duplicates(a,b);

  // const merger = [...dtttt,...hotelDetailsResponse.data.data.hotels];
  const all_data = {hotels:current_HOTELS,avg_lat_long:hotelDetailsResponse.data.data.avg_lat_long}
  yield put(getFullHotelListSuccess(all_data));
    }  
    yield put(getTotalHotelListCount(hotelDetailsResponse.data.total_count));
  } else {
    yield put(getFullHotelListFailure(hotelDetailsResponse.data));
  }
} catch (error) {
  // console.log("Network Error");
}
}

/************ Full Hotel Amenity List   *************** */
export function* getFullHotelAnimityListDatasAsync({ payload: data }) {
  try {
    const hotelAminityDataResponse = yield axios.get(
      `${backendUrlEndPoint}/amenity/get-all`,
      {
        headers: {
          mobile: "true",
          "Accept-Language": `${data.language}`,
        },
      }
    );
    if (hotelAminityDataResponse.data.success) {
      yield put(getHotelAnimitySuccess(hotelAminityDataResponse.data.data));
    } else {
      yield put(getHotelAnimityError(hotelAminityDataResponse.data));
    }
  } catch (error) {}
}

/************ Full Hotel Details By hotel slug    *************** */
export function* getFullHotelDetailsAsync({ payload: data }) {
  try {
    const extraurl =
      data.searchedParams.search_type == "bid"
        ? "&check_out_date=" + data.searchedParams.check_out_date + ""
        : " &book_for=" + data.searchedParams.book_for + "";
    const url =
      backendUrlEndPoint +
      "/hotel/get-hotel/" +
      data.hotel_slug +
      "?search_type=" +
      data.searchedParams.search_type +
      "&city=" +
      data.searchedParams.city +
      "&check_in_date=" +
      data.searchedParams.check_in_date +
      extraurl +
      "&adults=" +
      data.searchedParams.adults +
      "&children=" +
      data.searchedParams.children +
      "&rooms=" +
      data.searchedParams.rooms;

    const response = yield axios.get(url, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        mobile: "true",
        "Accept-Language": `${data.languageToShow}`,
        Authorization:
          store.getState().user.currentUser != null
            ? "Bearer " + store.getState().user.currentUser.token + ""
            : "",
      },
    });
    // const response = yield call(hotelService.getFullHotelDetails, data);
    if (response.data.success === true) {
      yield put(getFullHotelDetailsSuccess(response.data));
    } else {
      yield put(getFullHotelDetailsFailure(response.data));
    }
  } catch (error) {
    // console.log("Network Error");
  }
}

/************ Add Favourite Hotel    *************** */
export function* addFavouriteHotelAsync({ payload: data }) {


  try {
    const response = yield axios.post(
      `${backendUrlEndPoint}/hotel/add-favourite/`,
      data.postData,
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          mobile: true,
          "Accept-Language": `${data.languageToShow}`,
          Authorization:
            store.getState().user.currentUser != null
              ? "Bearer " + store.getState().user.currentUser.token + ""
              : "",
        },
      }
    );
    // const response = yield call(hotelService.addFavouriteHotel, data);
    // if (response.status = 200) {
    //   if (response.error.response.data.success === false) {
    //     if (response.error.response.data.message == "Token is required.") {
    //       errorToast("Please Login");
    //     }
    //   }
    // }
    if (response.data.success === true) {
      const afterfavoriteHotelList = store.getState().hotel.hotelListData.hotels;
      const upd_obj = afterfavoriteHotelList.findIndex((obj => obj._id == data.postData.hotel_id));
      afterfavoriteHotelList[upd_obj].is_favourite = true;  
      const hotelsUpdate = {hotels:afterfavoriteHotelList,avg_lat_long:store.getState().hotel.hotelListData.avg_lat_long};
      yield put(hotelListUpdateAfterAddandRemove(hotelsUpdate));
     // yield put(addFavouriteHotelSuccess(response.data));
     MySwal.fire({
      confirmButtonText: 'OK',
      confirmButtonColor: '#296da9',
      text:response.data.message
    });  
     // successToast(response.data.message);
    } else {
      yield put(addFavouriteHotelFailure(response.data));
      MySwal.fire({
        confirmButtonText: 'OK',
        confirmButtonColor: '#296da9',
        text:response.data.message
      });  
     // errorToast(response.data.message);
    }
  } catch (error) {
   if(error != undefined) {
    if (error.response.status == 401) {
      if (error.response.data.success === false) {
        if (error.response.data.message == "Token is required.") {
          MySwal.fire({
            confirmButtonText: 'OK',
            confirmButtonColor: '#296da9',
            text:"Please Login"
          }); 
         // errorToast("Please Login");
        }
      }
    }
  }
  }
}

/************ Remove Favourite Hotel    *************** */
export function* removeFavouriteHotelAsync({ payload: data }) {
  try {
    const response = yield axios.post(
      `${backendUrlEndPoint}/hotel/remove-favourite/`,
      data.postData,
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          mobile: true,
          "Accept-Language": `${data.languageToShow}`,
          Authorization:
            store.getState().user.currentUser != null
              ? "Bearer " + store.getState().user.currentUser.token + ""
              : "",
        },
      }
    );

    // const response = yield call(hotelService.removeFavouriteHotel, data);

    if (response.data.success == true) {
      yield put(removeFavouriteHotelSuccess(response.data));
      const afterfavoriteHotelList = store.getState().hotel.hotelListData.hotels;
      const upd_obj = afterfavoriteHotelList.findIndex((obj => obj._id == data.postData.hotel_id));
      afterfavoriteHotelList[upd_obj].is_favourite = false;  
      const hotelsUpdate = {hotels:afterfavoriteHotelList,avg_lat_long:store.getState().hotel.hotelListData.avg_lat_long};
      yield put(hotelListUpdateAfterAddandRemove(hotelsUpdate));
      MySwal.fire({
        confirmButtonText: 'OK',
        confirmButtonColor: '#296da9',
        text:response.data.message
      }); 
     // successToast(response.data.message);
    } else {
      yield put(removeFavouriteHotelFailure(response.data));
      MySwal.fire({
        confirmButtonText: 'OK',
        confirmButtonColor: '#296da9',
        text:response.data.message
      }); 
     // errorToast(response.data.message);
    }
  } catch (error) {
    // console.log("Network Error");
  }
}

/************ Favourites Hotel List    *************** */
export function* getAllFavouriteHotelListAsync({ payload: data }) {
  try {
    const response = yield axios.get(
      // `${backendUrlEndPoint}/hotel/get-all-favourite/`,
      `${backendUrlEndPoint}/hotel/get-all-favourite?skip=${data.skip}&limit=${data.limit}`,
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          mobile: true,
          "Accept-Language": `${data.languageToShow}`,
          Authorization:
            store.getState().user.currentUser != null
              ? "Bearer " + store.getState().user.currentUser.token + ""
              : "",
        },
      }
    );
    // const response = yield call(
    //   hotelService.getAllFavouriteHotelList,
    //   language
    // );

    if (response.data.success === true) {
      yield put(getAllFavouriteHotelListSuccess(response.data));
    } else {
      yield put(getAllFavouriteHotelListFailure(response.data));
      // errorToast(response.data.message);
    }
  } catch (error) {
    // console.log("Network Error");
  }
}
/************ Bid Checkout Details     *************** */
export function* getBidCheckoutDetailsAsync({ payload: data }) {
  try {
    // Get the timezone offset in minutes
    const offsetInMinutes = new Date().getTimezoneOffset();
    // Determine the sign of the offset (e.g., 330 or -330)
    const offsetSign = offsetInMinutes >= 0 ? '-' : '';
    // Get offset value
    const offsetValue = `${offsetSign}${Math.abs(offsetInMinutes)}`;

    const response = yield axios.post(
      "" + backendUrlEndPoint + "/hotel/get-bid-checkout-details",
      data.postData,
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          mobile: true,
          "Accept-Language": `${data.languageToShow}`,
          Authorization: data.token ? "Bearer " + data.token : "",
          "offset": offsetValue
        },
      }
    );

    if (response.data.success === true) {
      yield put(getBidCheckoutDetailsSuccess(response.data));
      // successToast(response.data.message);
    } else {
      yield put(getBidCheckoutDetailsFailure(response.data));
      MySwal.fire({
        confirmButtonText: 'OK',
        confirmButtonColor: '#296da9',
        text:response.data.message
      }); 
     // errorToast(response.data.message);
    }
  } catch (error) {
    // console.log("Network Error", error);
  }
}

/************ Hourly Checkout Details     *************** */
export function* getHourlyCheckoutDetailsAsync({ payload: data }) {
  try {
    // Get the timezone offset in minutes
    const offsetInMinutes = new Date().getTimezoneOffset();
    // Determine the sign of the offset (e.g., 330 or -330)
    const offsetSign = offsetInMinutes >= 0 ? '-' : '';
    // Get offset value
    const offsetValue = `${offsetSign}${Math.abs(offsetInMinutes)}`;

    const response = yield axios.post(
      "" + backendUrlEndPoint + "/hotel/get-hourly-checkout-details",
      data.postData,
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          mobile: true,
          "Accept-Language": `${data.languageToShow}`,
          Authorization: data.token ? "Bearer " + data.token : "",
          "offset": offsetValue
        },
      }
    );

    if (response.data.success === true) {
      yield put(getHourlyCheckoutDetailsSuccess(response.data));
      // successToast(response.data.message);
    } else {
      yield put(getHourlyCheckoutDetailsFailure(response.data));
      MySwal.fire({
        confirmButtonText: 'OK',
        confirmButtonColor: '#296da9',
        text:response.data.message
      }); 
      //  errorToast(response.data.message);
    }
  } catch (error) {
    // console.log("Network Error", error);
  }
}

/************ Hotel Booking     *************** */
export function* hotelBookingAsync({ payload: data }) {
  try {
    // Get the timezone offset in minutes
    const offsetInMinutes = new Date().getTimezoneOffset();
    // Determine the sign of the offset (e.g., 330 or -330)
    const offsetSign = offsetInMinutes >= 0 ? '-' : '';
    // Get offset value
    const offsetValue = `${offsetSign}${Math.abs(offsetInMinutes)}`;

    const response = yield axios.post(
      "" + backendUrlEndPoint + "/hotel/booking",
      data.postData,
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          mobile: true,
          "Accept-Language": `${data.languageToShow}`,
          Authorization: data.token ? "Bearer " + data.token : "",
          "offset": offsetValue
        },
      }
    );

    if (response.data.success === true) {
      yield put(hotelBookingSuccess(response.data));
      // successToast(response.data.message);
    } else {
      yield put(hotelBookingFailure(response.data));
      MySwal.fire({
        confirmButtonText: 'OK',
        confirmButtonColor: '#296da9',
        text:response.data.message
      }); 
    //  errorToast(response.data.message);
    }
  } catch (error) {
    // console.log("Network Error", error);
  }
}

/********** User Hotel Booking List History Handleing  ***********/
export function* userBookingHistoryList({ payload: data }) {
  try {
    const response = yield axios.get(
      // ""+backendUrlEndPoint+"/booking/get-all?filterbystatus=" +
      //   data.filterbystatus +
      //   "",
      `${backendUrlEndPoint}/booking/get-all`,

      {
        // headers: {
        //   "Access-Control-Allow-Origin": "*",
        //   mobile: true,
        //   "Accept-Language": `${data.languageToShow}`,
        //   Authorization: data.token ? "Bearer " + data.token : "",
        // },
        headers: {
          "Access-Control-Allow-Origin": "*",
          mobile: true,
          "Accept-Language": `${data.languageToShow}`,
          Authorization:
            store.getState().user.currentUser != null
              ? "Bearer " + store.getState().user.currentUser.token + ""
              : "",
        },
        params: {
          filterbystatus: data.filterbystatus,
          skip: data.skip,
          limit: data.limit,
        },
      }
    );

    if (response.data.success === true) {
      yield put(hotelBookingHistorySuccess(response.data));
      // successToast(response.data.message);
    } else {
      yield put(hotelBookingHistoryFailure(response.data));
      // errorToast(response.data.message);
    }
  } catch (error) {
    // console.log("Network Error", error);
  }
}

/************ Apply Wallet     *************** */
export function* applyWalletAsync({ payload: data }) {
  try {
    const response = yield axios.post(
      "" + backendUrlEndPoint + "/wallet/point-apply/",
      data.postData,
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          mobile: true,
          "Accept-Language": `${data.languageToShow}`,
          Authorization: data.token ? "Bearer " + data.token : "",
        },
      }
    );

    if (response.data.success === true) {
      yield put(applyWalletSuccess(response.data));
      MySwal.fire({
        confirmButtonText: 'OK',
        confirmButtonColor: '#296da9',
        text:response.data.message
      }); 
    //  successToast(response.data.message);
    } else {
      yield put(applyWalletFailure(response.data));
      MySwal.fire({
        confirmButtonText: 'OK',
        confirmButtonColor: '#296da9',
        text:response.data.message
      }); 
    //  errorToast(response.data.message);
    }
  } catch (error) {
    // console.log("Network Error");
  }
}

/************ Apply Promo Code     *************** */
export function* applyPromoCodeAsync({ payload: data }) {
  try {
    const response = yield axios.post(
      "" + backendUrlEndPoint + "/coupon/apply/",
      data.postData,
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          mobile: true,
          "Accept-Language": `${data.languageToShow}`,
          Authorization: data.token ? "Bearer " + data.token : "",
        },
      }
    );

    if (response.data.success === true) {
      yield put(applyPromoCodeSuccess(response.data));
      MySwal.fire({
        confirmButtonText: 'OK',
        confirmButtonColor: '#296da9',
        text:response.data.message
      }); 
     // successToast(response.data.message);
    } else {
      yield put(applyPromoCodeFailure(response.data));
      MySwal.fire({
        confirmButtonText: 'OK',
        confirmButtonColor: '#296da9',
        text:response.data.message
      }); 
     // errorToast(response.data.message);
    }
  } catch (error) {
    // console.log("Network Error");
  }
}

/************ Bid Your Amount     *************** */
export function* bidYourAmountAsync({ payload: data }) {
  try {
    const response = yield axios.post(
      "" + backendUrlEndPoint + "/biding-request/add/",
      data.postData,
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          mobile: true,
          "Accept-Language": `${data.languageToShow}`,
          Authorization: data.token ? "Bearer " + data.token : "",
        },
      }
    );

    if (response.data.success === true) {
      yield put(bidYourAmountSuccess(response.data));
      MySwal.fire({
        confirmButtonText: 'OK',
        confirmButtonColor: '#296da9',
        text:response.data.message
      }); 
     // successToast(response.data.message);
    } else {
      yield put(bidYourAmountFailure(response.data));
      MySwal.fire({
        confirmButtonText: 'OK',
        confirmButtonColor: '#296da9',
        text:response.data.message
      }); 
    //  errorToast(response.data.message);
    }
  } catch (error) {
    // console.log("Network Error");
  }
}

/************ Apply Hotel Offers     *************** */
export function* applyHotelOffersAsync({ payload: data }) {
  try {
    const response = yield axios.post(
      "" + backendUrlEndPoint + "/hotel/apply-offers/",
      data.postData,
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          mobile: true,
          "Accept-Language": `${data.languageToShow}`,
          Authorization: data.token ? "Bearer " + data.token : "",
        },
      }
    );

    if (response.data.success === true) {
      yield put(applyHotelOffersSuccess(response.data));
      MySwal.fire({
        confirmButtonText: 'OK',
        confirmButtonColor: '#296da9',
        text:response.data.message
      }); 
    //  successToast(response.data.message);
    } else {
      yield put(applyHotelOffersFailure(response.data));
      MySwal.fire({
        confirmButtonText: 'OK',
        confirmButtonColor: '#296da9',
        text:response.data.message
      }); 
     // errorToast(response.data.message);
    }
  } catch (error) {
    // console.log("Network Error", error);
  }
}

/** HOTEL DATA WEATHER API WORKING  ***/
export function* hotelWeatherDataHandle({ payload: data }) {
  try {
    const response = yield axios.post(
      `${weatherAPIEndPoint}/current.json?key=${process.env.REACT_APP_WeatherAPI}&q=${data}&aqi=no`
    );
    
    if (response.status === 200) {
      yield put(hotelWheatherApiSuccess(response.data));
      //  successToast(response.data.message);
    } else {
      yield put(hotelWheatherApiFailure(response));
      MySwal.fire({
        confirmButtonText: 'OK',
        confirmButtonColor: '#296da9',
        text:response.data.message
      }); 
    //  errorToast(response.data.message);
    }
  } catch (error) {
    // console.log("Network Error", error);
  }
}
 
/*************** HOTEL PERFERENCE LIST  ******************/
export function* hotelPerferenceListAsync({ payload: data }) {
  try {
    const response = yield axios.get(
      "" + backendUrlEndPoint + "/hotel/get-preference",
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          mobile: true,
          "Accept-Language": `${data.language}`,
          Authorization: data.token ? "Bearer " + data.token : "",
        },
      }
    );

    if (response.data.success === true) {
      yield put(hotelPerferenceListHandleSuccess(response.data.data));
    //  successToast(response.data.message);
    } else {
      yield put(hotelPerferenceListDataListFailure(response.data));
    //  errorToast(response.data.message);
    }
  } catch (error) {
    // console.log("Network Error", error);
  }
}



/************ HOTEL RE-SEND CONFIRMATION  *************** */
export function* hotelResendConfirmationAsync({ payload: data }) {
  try {
    const response = yield axios.post(
      "" + backendUrlEndPoint + "/hotel/resend-booking-email",
      data.postData,
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          mobile: true,
          "Accept-Language": `${data.languageToShow}`,
          Authorization: data.token ? "Bearer " + data.token : "",
        },
      }
    );

    if (response.data.success === true) {
      yield put(hotelResendConfirmationSuccess(response.data));
      MySwal.fire({
        confirmButtonText: 'OK',
        confirmButtonColor: '#296da9',
        text:response.data.message
      }); 
     // successToast(response.data.message);
    } else {
      yield put(hotelResendConfirmationFailure(response.data));
      MySwal.fire({
        confirmButtonText: 'OK',
        confirmButtonColor: '#296da9',
        text:response.data.message
      }); 
    //  errorToast(response.data.message);
    }
  } catch (error) {
    // console.log("Network Error", error);
  }
}

export function* getAddHotelReviewAddRequestAsync({payload: data}) {

   try {
    const response = yield axios.post(
      "" + backendUrlEndPoint + "/hotel/add-review-and-rating",
      data,
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          mobile: true,
          "Accept-Language": `${store.getState().language.language}`,
          Authorization:  store.getState().user.currentUser != null
          ? "Bearer " + store.getState().user.currentUser.token + ""
          : "",
        },
      }
    );

    if (response.data.success === true) {
      yield put(hotelBookingListAddReviewSuccess(response.data));
      MySwal.fire({
        confirmButtonText: 'OK',
        confirmButtonColor: '#296da9',
        text:response.data.message
      }); 
     // successToast(response.data.message);
    } else {
      yield put(hotelBookingListAddReviewSuccess(response.data));
      MySwal.fire({
        confirmButtonText: 'OK',
        confirmButtonColor: '#296da9',
        text:response.data.message
      }); 
     // errorToast(response.data.message);
    }
  } catch (error) {
    // console.log("Network Error", error);
  }  
}

/** HOTEL DATA WEATHER API WORKING  ***/


export function* hotelBookingDetailsSuccessDataAsync({payload: data}) {
  try {
    const response = yield axios.get(
      "" + backendUrlEndPoint + "/booking/get-details/"+data.ticket+"",
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          mobile: true,
          "Accept-Language": `${store.getState().language.language}`,
          Authorization:  store.getState().user.currentUser != null
          ? "Bearer " + store.getState().user.currentUser.token + ""
          : "",
        },
      }
    );

    if (response.data.success === true) {
      yield put(hotelBookingListSuccess(response.data));
  //    successToast(response.data.message);
    } else {
     // yield put(hotelBookingListSuccess(response.data));
     MySwal.fire({
      confirmButtonText: 'OK',
      confirmButtonColor: '#296da9',
      text:response.data.message
    }); 
    //  errorToast(response.data.message);
    }
  } catch (error) {
    // console.log("Network Error", error);
  }  
}

export function* hotelBookingCancellationRequestAsync({payload: data}) {
  try {
    const response = yield axios.post(
      "" + backendUrlEndPoint + "/biding-request/update/",
      data,
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          mobile: true,
          "Accept-Language": `${store.getState().language.language}`,
          Authorization:  store.getState().user.currentUser != null
          ? "Bearer " + store.getState().user.currentUser.token + ""
          : "",
        },
      }
    );

    if (response.data.success === true) {
     yield put(hotelBiddingCancelationSuccess(response.data));
     MySwal.fire({
      confirmButtonText: 'OK',
      confirmButtonColor: '#296da9',
      text:response.data.message
    }); 
   // successToast(response.data.message);
    } else {
     // yield put(hotelBookingListSuccess(response.data));
     MySwal.fire({
      confirmButtonText: 'OK',
      confirmButtonColor: '#296da9',
      text:response.data.message
    }); 
     // errorToast(response.data.message);
    }
  } catch (error) {
    // console.log("Network Error", error);
  }  
}

export function* hotelStarFilterRequestAsync({payload: data}) {
  try {
    const response = yield axios.get(
      "" + backendUrlEndPoint + "/star/get-all",
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          mobile: true,
          "Accept-Language": `${store.getState().language.language}`
        },
      }
    );

    if (response.data.success === true) {
     yield put(hotelStarFilterApiCallSuccess(response.data));
   // successToast(response.data.message);
    } else {
     yield put(hotelStarFilterApiCallFailure(response.data));
     MySwal.fire({
      confirmButtonText: 'OK',
      confirmButtonColor: '#296da9',
      text:response.data.message
    }); 
     // errorToast(response.data.message);
    }
  } catch (error) {
    // console.log("Network Error", error);
  }  
}


export function* getFullHotelDetailsFetchStart() {
  yield takeLatest(
    HotelActionTypes.GET_FULL_HOTELS_DETAILS_REQUEST,
    getFullHotelDetailsAsync
  );
}

export function* getFullHotelListFetchStart() {
  yield takeLatest(
    HotelActionTypes.GET_HOTEL_LIST_SEARCH_START,
    getFullHotelListDatasAsync
  );
}

export function* getHotelAminityListFetchStart() {
  yield takeLatest(
    HotelActionTypes.GET_HOTEL_ANIMITY_LIST_START,
    getFullHotelAnimityListDatasAsync
  );
}
export function* addFavouriteHotelFetchStart() {
  yield takeLatest(
    HotelActionTypes.ADD_TO_FAVOURITE_HOTEL_REQUEST,
    addFavouriteHotelAsync
  );
}

export function* removeFavouriteHotelFetchStart() {
  yield takeLatest(
    HotelActionTypes.REMOVE_TO_FAVOURITE_HOTEL_REQUEST,
    removeFavouriteHotelAsync
  );
}

export function* getAllFavouriteHotelListFetchStart() {
  yield takeLatest(
    HotelActionTypes.GET_ALL_FAVOURITE_HOTEL_LIST_REQUEST,
    getAllFavouriteHotelListAsync
  );
}
/********** Hottel Weather Data API *************/
export function* hotelWeatherAPIDataFetching() {
  yield takeLatest(
    HotelActionTypes.HOTEL_WHEATHER_API_REQUEST,
    hotelWeatherDataHandle
  );
}
/********** Hottel Weather Data API *************/

/************ Bid Checkout Details     *************** */
export function* getBidCheckoutDetailsFetchStart() {
  yield takeLatest(
    HotelActionTypes.GET_BID_CHECKOUT_DETAILS_REQUEST,
    getBidCheckoutDetailsAsync
  );
}

/************ Hourly Checkout Details  *************** */
export function* getHourlyCheckoutDetailsFetchStart() {
  yield takeLatest(
    HotelActionTypes.GET_HOURLY_CHECKOUT_DETAILS_REQUEST,
    getHourlyCheckoutDetailsAsync
  );
}

/************ Hourly Checkout Details  *************** */
export function* hotelBookingFetchStart() {
  yield takeLatest(HotelActionTypes.HOTEL_BOOKING_REQUEST, hotelBookingAsync);
}

/******** User Hotel Booking List History Handleing *******/
export function* hotelBookingHistoryFetchStart() {
  yield takeLatest(
    HotelActionTypes.HOTEL_BOOKING_LIST_DATA_FETCH,
    userBookingHistoryList
  );
}

/************ Apply Wallet  *************** */
export function* applyWalletFetchStart() {
  yield takeLatest(HotelActionTypes.APPLY_WALLET_REQUEST, applyWalletAsync);
}

/************ Apply Promo Code  *************** */
export function* applyPromoCodeFetchStart() {
  yield takeLatest(
    HotelActionTypes.APPLY_PROMO_CODE_REQUEST,
    applyPromoCodeAsync
  );
}

/************ Bid Your Amount  *************** */
export function* bidYourAmountFetchStart() {
  yield takeLatest(
    HotelActionTypes.BID_YOUR_AMOUNT_REQUEST,
    bidYourAmountAsync
  );
}

/************ Apply Hotel Offers  *************** */
export function* applyHotelOffersFetchStart() {
  yield takeLatest(
    HotelActionTypes.APPLY_HOTEL_OFFERS_REQUEST,
    applyHotelOffersAsync
  );
}

/************ HOTEL RE-SEND CONFIRMATION  *************** */
export function* hotelResendConfirmationFetchStart() {
  yield takeLatest(
    HotelActionTypes.HOTEL_RESEND_CONFIRMATION_REQUEST,
    hotelResendConfirmationAsync
  );
}


/************ HOTEL Fetch Perference List  *************** */
export function* hotelPerferenceListFetchStart() {
  yield takeLatest(
    HotelActionTypes.HOTEL_PERFERENCE_API_REQUEST,
    hotelPerferenceListAsync
  );
}

/************ HOTEL Fetch Pagination  List  *************** */
export function* getFullHotelListPaginationFetchStart() {
  yield takeLatest(
    HotelActionTypes.GET_HOTEL_PAGINATION_LIST_SEARCH_START,
    getFullHotelListDatasAsync
  );
}

/******** Hotel Review Added ********/
export function* AddHotelReviewRequest() {
  yield takeLatest(
    HotelActionTypes.POST_HOTEL_RATING_REVIEW_REQUEST,
    getAddHotelReviewAddRequestAsync
  );
}

/************* Hotel Booking Details ***********/
export function* HotelBokingSuccessDetails() {
  yield takeLatest(
    HotelActionTypes.HOTEL_BOOKING_DETAILS_REQUEST,
    hotelBookingDetailsSuccessDataAsync
  );
}

export function*  HotelRoomBiddingCancelation() {
  yield takeLatest(
    HotelActionTypes.HOTEL_BIDDING_CENCEL_REQUEST,
    hotelBookingCancellationRequestAsync
  );
}

export function*  HotelStarFilterRequestStart() {
  yield takeLatest(
    HotelActionTypes.HOTEL_STAR_API_FILTER_REQUEST,
    hotelStarFilterRequestAsync
  );
}

export function* hotelSagas() {
  yield all([
    call(getFullHotelDetailsFetchStart),
    call(getFullHotelListFetchStart),
    call(getHotelAminityListFetchStart),
    call(getFullHotelDetailsFetchStart),
    call(getFullHotelListFetchStart),
    call(addFavouriteHotelFetchStart),
    call(removeFavouriteHotelFetchStart),
    call(getAllFavouriteHotelListFetchStart),
    call(getBidCheckoutDetailsFetchStart),
    call(getHourlyCheckoutDetailsFetchStart),
    call(hotelBookingFetchStart),
    call(hotelBookingHistoryFetchStart),
    call(applyWalletFetchStart),
    call(applyPromoCodeFetchStart),
    call(bidYourAmountFetchStart),
    call(applyHotelOffersFetchStart),
    call(hotelWeatherAPIDataFetching),
    call(hotelResendConfirmationFetchStart),
    call(hotelPerferenceListFetchStart),
    call(getFullHotelListPaginationFetchStart),
    call(AddHotelReviewRequest),
    call(HotelBokingSuccessDetails),
    call(HotelRoomBiddingCancelation),
    call(HotelStarFilterRequestStart)
  ]);
}
