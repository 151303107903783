import React, { useEffect, useState } from 'react';
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { useConfirmDialogStyles } from "./../../components/common/dialogs/ConfirmDialogStyles";
import DialogTitle from "@mui/material/DialogTitle";
import { GrClose } from "react-icons/gr";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CancelIcon from '@mui/icons-material/Cancel';
import { useLocation, useParams, useSearchParams, useNavigate, } from 'react-router-dom';
import { hotelBookingListRequest } from "./../../redux/hotels/hotel.actions";
import { selectHotelBookingDetails } from "./../../redux/hotels/hotel.selectors";
import { selectlanguageToShow } from "./../../redux/language/language.selectors";
import BookingConfirmationDetailsModal from "./../../components/checkout/BookingConfirmationDetailsModal";
import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { Helmet } from 'react-helmet';
import { data } from 'jquery';
const OrderConfirmedPage = ({ hotelBookingListRequest, bookingDetails, languageToShow }) => {
  const [t] = useTranslation("common");
  const navigate = useNavigate();
  const classes = useConfirmDialogStyles();
  const location = useLocation();
  let [searchParams, setSearchParams] = useSearchParams();
  const ticket = searchParams.get('oid');
  const bookingStatus = searchParams.get('status');
  const ticketid = searchParams.get('tid');
  const bidid = searchParams.get('bid');
  const hotelname = searchParams.get('hotel_name');
  const [bookingConformOpen, setBookingConformOpen] = useState(false);
  useEffect(() => {
    if (ticket != null) {
      hotelBookingListRequest({ ticket });
    }
  }, [ticket, languageToShow]);
  return (


    <React.Fragment>

       {/* for SEO purpose */}
       <Helmet>
        <title>Order Confirmed - Thank You for Your Purchase | Wfrlee</title>
        <meta name="description" content="Thank you for your purchase! Your order has been confirmed. Check your email for details and track your order on Wfrlee. We hope you enjoy your selection!" />
      </Helmet>

      <div className='conformedpop'>
        <div className={classes.dialogPopup} >
          <DialogTitle sx={{ pb: 3 }}>
            <Box sx={{ position: "absolute", right: 0, left: "auto", top: 0 }}>
            </Box>
          </DialogTitle>
          <DialogContent className={classes.dialogContent}>
            <Box className={classes.dialogText}>
              <Box className={bookingStatus == "success" ? classes.checkIconSuccess : classes.checkIconFailure}>
                {bookingStatus == "success" ? <CheckCircleOutlineIcon /> : <CancelIcon />}

              </Box>
              {
                ticket != null ?
                  <Typography className={classes.heading}>{`${ticket != null && bookingStatus == "success" ? t("confirmationPage.bookingConfirmationSuccess") : ticket == null && bookingStatus == "success" ? "Bidding Confirmed Successful" : t("confirmationPage.bookingFailed")}`}</Typography>
                  : <Typography className={classes.heading}>{t("confirmationPage.biddingConformationSuccess")}</Typography>
              }


              {bookingStatus == "success" ? <Box className={classes.text}>{` ${ticket != null ? t("confirmationPage.yourbookingnumberis") : t("confirmationPage.yourbiddingnumberis")}  ${ticket != null ? ticket : bidid} ${t("confirmationPage.forhotel")} ${hotelname != null ? hotelname : ""}`}</Box> : ''}
            </Box>
          </DialogContent>

          <div style={{ display: "flex", justifyContent: "center", flexDirection: "row", padding: "20px 40px !important", gap: "5px" }} >
            {bookingStatus == "success" ? ticket != null ?
              <Button className={classes.yesBtn}
                onClick={() => { setBookingConformOpen(true) }}
                disabled={bookingDetails != null ? false : true}
              >
                {t("confirmationPage.viewTheBookingDetails")}
              </Button>
              : <Button className={classes.yesBtn}
                onClick={() => { navigate("/mybidding") }}

              >
                {t("confirmationPage.viewTheBiddingDetails")}
              </Button>
              : null
            }


            <Button className={classes.noBtn} onClick={() => { navigate("/") }} >
              {t("confirmationPage.goToHome")}
            </Button>
          </div>


        </div>
      </div>
      <BookingConfirmationDetailsModal
        setShowDialog={setBookingConformOpen}
        isOpen={bookingConformOpen}
        bookingData={bookingDetails != null ? bookingDetails.data : null}
        cancelButtonLabel={t("checkoutPage.Close")}
        cancelDialogHandler={() => {
          setBookingConformOpen(false);
          navigate("/mybooking");

        }}
      />
    </React.Fragment>





  )
}


const mapStateToProps = createStructuredSelector({
  bookingDetails: selectHotelBookingDetails,
  languageToShow: selectlanguageToShow,
});

const mapDispatchToProps = dispatch => ({
  hotelBookingListRequest: data => dispatch(hotelBookingListRequest(data)),

});

export default connect(mapStateToProps, mapDispatchToProps)(OrderConfirmedPage);