import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import {
  emailVerificationStart,
  stateClearAfterTask,
} from "./../../redux/user/user.actions";
import { selectEmailVerificationMessage } from "./../../redux/user/user.selectors";
const EmailVerification = ({
  emailVerification,
  selectEmailVerificationMessage,
  stateClearAfterTask,
}) => {
  const params = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    emailVerification(params);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);
  useEffect(() => {
    if (selectEmailVerificationMessage !== null) {
      if (selectEmailVerificationMessage.success) {
        stateClearAfterTask();
        navigate("/login");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectEmailVerificationMessage]);
  return (
    <>

    {/* for SEO purpose */}
    <Helmet>
        <title>Verify Your Email - Secure Your Account | Wfrlee</title>
        <meta name="description" content="Complete your email verification to secure and activate your Wfrlee account. Confirm your email to ensure all future communications reach you safely." />
      </Helmet>

      <h2>Email Verification</h2>
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  selectEmailVerificationMessage: selectEmailVerificationMessage,
});
const mapDispatchToProps = (dispatch) => ({
  emailVerification: (data) => dispatch(emailVerificationStart(data)),
  stateClearAfterTask: () => dispatch(stateClearAfterTask()),
});

export default connect(mapStateToProps, mapDispatchToProps)(EmailVerification);
