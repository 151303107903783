import { createSelector } from "reselect";

const selectOffer = (state) => state.offer;

export const selectCouponListData = createSelector(
  [selectOffer],
  (offer) => offer.couponListData
);

export const selectCouponListLoading = createSelector(
  [selectOffer],
  (offer) => offer.couponListLoading
);
