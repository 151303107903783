import React from "react";
import { Button } from "react-bootstrap";
import "./CustomPopupModal.css";
import { GrClose } from "react-icons/gr";
import $ from "jquery";

const CustomPopupModal = ({ setShow, show, headerTitle, children }) => {
  const ref = React.useRef(null);

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setShow(false);
    }
  };

  React.useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  $("select.goog-te-combo").change(function () {
    var index = $(this).val();
    {
      [...Array($("select.goog-te-combo option").length)].map((e, i) => {
        selectedOptionClick(i);
      });
    }
  });

  const selectedOptionClick = React.useCallback((id) => {
    document.getElementById("modalId").classList.remove("show");
    document.getElementById("backdropId").classList.remove("show");
    $("#google_translate_element").css("display", "block");
    setShow(false);
  }, []);

  return (
    <div
      className={show == true ? "show popup-modal-backdrop" : ""}
      id="backdropId"
    >
      <div
        className={show == true ? "popupModal show" : "popupModal"}
        ref={ref}
        id="modalId"
      >
        <div className="modalHeader">
          <p>{headerTitle} </p>

          <Button
            className={"popclose onClickBtn"}
            onClick={() => setShow(!show)}
          >
            <GrClose />
          </Button>
        </div>
        <div className="content">{children}</div>
      </div>
    </div>
  );
};

export default CustomPopupModal;
