import FileUploadActionTypes from "./fileUpload.types";

export const careerFileUploadRequest = (fileData) => {
  return {
    type: FileUploadActionTypes.CAREER_FILE_UPLOAD_REQUEST,
    payload: fileData,
  };
};
export const careerFileUploadSuccess = (fileData) => ({
  type: FileUploadActionTypes.CAREER_FILE_UPLOAD_SUCCESS,
  payload: fileData,
});

export const careerFileUploadFailed = (error) => ({
  type: FileUploadActionTypes.CAREER_FILE_UPLOAD_FAILED,
  payload: error,
});

export const resetFileStore = (error) => ({
  type: FileUploadActionTypes.RESET_FILE_STORE,
});
