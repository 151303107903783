import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {
  getAllFavouriteHotelListRequest,
  addFavouriteHotelRequest,
  removeFavouriteHotelRequest,
} from "../../redux/hotels/hotel.actions";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { selectFavouriteHotelList } from "../../redux/hotels/hotel.selectors";
import { selectUserLoginData } from "../../redux/user/user.selectors";

import { selectlanguageToShow } from "./../../redux/language/language.selectors";
import MyFavouriteHotelList from "../../components/Hotels/MyFavouriteHotelList";
import Pagination from "@mui/material/Pagination";
import { useTranslation } from "react-i18next";
import MyAccountLeftMenu from "../../components/MyAccount/MyAccountLeftMenu";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { Helmet } from "react-helmet";
const MyfavoritesPage = ({
  getAllFavouriteHotelListRequest,
  favouriteHotelListData,
  addFavouriteHotelRequest,
  removeFavouriteHotelRequest,
  languageToShow,
  userAuthData,
}) => {
  const MySwal = withReactContent(Swal);
  const [t] = useTranslation("common");
  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(3);
  const [isAddedFavourite, setIsAddedFavourite] = React.useState();

  var collecSlider = {
    arrows: true,
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const perPageLimit = 3;

  const data = {
    languageToShow: languageToShow,
    token: userAuthData != null ? userAuthData.token : "",
    skip: page,
    limit: perPageLimit,
  };

  React.useEffect(() => {
    getAllFavouriteHotelListRequest(data);
    setPage(1);
    setRowsPerPage(perPageLimit);
    window.scroll(0, 0);
  }, [languageToShow]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    const data = {
      languageToShow: languageToShow,
      token: userAuthData != null ? userAuthData.token : "",
      skip: newPage,
      limit: perPageLimit,
    };
    getAllFavouriteHotelListRequest(data);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };



  const handleFavourite = (hotel_id) => {
    const postData = {
      hotel_id: hotel_id,
    };

    const hotelFavouritesParam = {
      postData,
      languageToShow: languageToShow,
      token: userAuthData != null ? userAuthData.token : "",
    };

    if (userAuthData != null) {
      if (hotel_id) {
        removeFavouriteHotelRequest(hotelFavouritesParam);
        setIsAddedFavourite(!isAddedFavourite);
        getAllFavouriteHotelListRequest(data);
      }

    } else {
      MySwal.fire({
        confirmButtonText: 'OK',
        confirmButtonColor: '#296da9',
        text: t("MyAccount.MyFavouritesPage.Pleaseloginfirst")
      });
    }
  };

  return (
    <>

    {/* for SEO purpose */}
    <Helmet>
        <title>Your Favorites - Personalized Collection | Wfrlee</title>
        <meta name="description" content="Easily access and manage your favorite listings on Wfrlee. Bookmark accommodations, services, and offers to quickly revisit them anytime. Organize your top choices in one convenient place." />
      </Helmet>

      {/* //////////Myprofile page Section/////////// */}
      <div className="myaccountpage">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="accounttotalsection">
                <div className="leftmenu">
                  <div className="account-titlearea">
                    <h2>{t("MyAccount.heading")}</h2>
                    <p>{t("MyAccount.subHeading")}</p>
                  </div>
                  <MyAccountLeftMenu />
                </div>
                <div className="rightsection">
                  <h1>{t("MyAccount.MyFavouritesPage.heading")}</h1>
                  <div className="accountpoint notificaton">
                    {/* ///review slider content start/// */}

                    {favouriteHotelListData != null ? (
                      <>
                        {
                          (favouriteHotelListData?.data || []).map(
                            (myFavouriteHotelList, index) => (
                              <MyFavouriteHotelList
                                key={index}
                                myFavouriteHotelList={myFavouriteHotelList}
                                favouritesListRequestData={data}
                              />
                            )
                          )}
                      </>
                    ) : (
                      // If no data
                      <div className="WL_right_rivewbox">
                        <div className="WL_collection_left WL_collection_myfavlist_left">
                          <p>{t("MyAccount.MyFavouritesPage.NoDataFound")}</p>
                        </div>
                      </div>
                    )}


                    <Pagination
                      boundaryCount={1}
                      count={
                        favouriteHotelListData != null
                          ? Math.ceil(
                            favouriteHotelListData?.total_count / perPageLimit
                          )
                          : 0
                      }
                      defaultPage={1}
                      onChange={handleChangePage}
                      page={page}
                      showFirstButton={true}
                      showLastButton={true}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* //////////Myprofile page Section/////////// */}
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  languageToShow: selectlanguageToShow,
  favouriteHotelListData: selectFavouriteHotelList,
  userAuthData: selectUserLoginData,
});
const mapDispatchToProps = (dispatch) => ({
  getAllFavouriteHotelListRequest: (data) =>
    dispatch(getAllFavouriteHotelListRequest(data)),
  addFavouriteHotelRequest: (data) => dispatch(addFavouriteHotelRequest(data)),
  removeFavouriteHotelRequest: (data) =>
    dispatch(removeFavouriteHotelRequest(data)),
});
export default connect(mapStateToProps, mapDispatchToProps)(MyfavoritesPage);